import React from 'react';

export const IcoGoal: React.FC = (props) => (
  <svg viewBox="0 0 16 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3847 10.6314H5.44229L3.9647 6.04797L7.83705 3.41509L11.9642 6.19411L10.3847 10.6314ZM13.9848 5.46726L15.0545 3.71635C14.6828 3.05626 14.2126 2.45337 13.662 1.92529C12.7314 1.03617 11.5787 0.367271 10.2889 0.000366721L7.85621 1.56113L5.71857 0C4.42779 0.367088 3.27495 1.03599 2.34399 1.92529C1.83243 2.41541 1.39214 2.97081 1.03404 3.5759L1.97076 5.61505L0 7.34689C0.00210756 9.22194 0.703349 10.9283 1.87055 12.2597L4.6577 12.0765L5.65534 14.6648C6.3972 14.8819 7.18485 14.9996 8.00278 15C8.8458 14.9996 9.65644 14.8751 10.4177 14.6448L11.4561 12.146L14.1011 12.2976C15.2419 11.0134 15.9446 9.37358 16 7.56821L13.9848 5.46726Z"
      fill="white"
    />
  </svg>
);
