import React, { useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Avatar } from 'shared/components/Avatar';
import { Modal } from 'shared/components/modal/Modal';
import { RatingInput } from 'shared/components/RatingInput';
import { Button } from 'shared/components/buttons/Button';
import { UserProfile } from 'shared/types/UserProfile';
import { fetchProfile } from './api';
import { useSelector } from 'react-redux';
import { profileSelector } from './store/profileSelectors';
import Styled from './RateMe.styles';

export const RatingStats = [
  'Goalkeeper',
  'Defender',
  'Midfielder',
  'Attacker',
  'Speed',
  'Strength',
  'Work rate',
  'Technique',
  'Heading',
  'Dribbling',
  'Teamwork',
  'Temperament',
];

const storedRating = (uid: string): Record<string, number> => {
  const rating = localStorage.getItem(`user-${uid}`);
  return rating && JSON.parse(rating);
};

export const RateMe: React.FC<RouteComponentProps<{ uid: string }>> = ({
  uid,
  ...props
}) => {
  const userProfile = useSelector(profileSelector);
  const [ratingProfile, setRatingProfile] = useState<UserProfile | null>(null);
  const [rating, setRating] = useState<Record<string, number>>(
    storedRating(uid!) || {}
  );
  const [totalRating, setTotalRating] = useState<
    Record<string, { avg: number; count: number }>
  >({});
  // user can not rate his own profile, also rating can only be done once
  // rating is stored in localStorage after user submits it
  const [isReadOnly, setIsReadOnly] = useState(
    userProfile?.userId === uid || localStorage.getItem(`user-${uid}`) != null
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!userProfile || userProfile.userId !== uid) {
      fetchProfile(uid!).then((response) => setRatingProfile(response.data));
    } else {
      setRatingProfile(userProfile);
    }
  }, [uid, userProfile]);

  const submitRating = (): void => {
    if (Object.keys(rating).length !== RatingStats.length) {
      toast('Please rate all player attributes before submitting.');
      return;
    }

    localStorage.setItem(`user-${uid}`, JSON.stringify(rating));

    axios
      .post(`/api/users/${uid}/rating`, { rating })
      .then((resp) => {
        setTotalRating(resp.data);
        setIsReadOnly(true);
        setShowModal(true);
      })
      .catch(() =>
        toast.error('Error submitting your rating. Please try again later.')
      );
  };

  return ratingProfile ? (
    <Styled.Wrapper>
      <img className="logo" src="/images/logo_footify.svg" width={140} />
      <Avatar
        size={120}
        position={ratingProfile.position}
        src={ratingProfile?.profileImagePathThumbnail}
      />
      <Styled.TextBright>{ratingProfile.fullName}</Styled.TextBright>
      <Styled.TextDark>
        {ratingProfile.playingStyle}. {ratingProfile.preferredFoot} footed
      </Styled.TextDark>
      {isReadOnly ? (
        <>
          <Styled.TextBright>
            Thank you for rating {ratingProfile.fullName}&apos;s
            <br />
            football skills.
          </Styled.TextBright>
          <br />
          <Styled.TextDark>See what you gave below 😉</Styled.TextDark>
        </>
      ) : (
        <>
          <Styled.TextBright style={{ fontWeight: 600, marginTop: '2rem' }}>
            Rate {ratingProfile.fullName}&apos;s football skills.
          </Styled.TextBright>
          <Styled.TextDark style={{ marginTop: '1rem' }}>
            We won&lsquo;t tell them it was you 😉
          </Styled.TextDark>
        </>
      )}
      <Styled.Scoring>
        <h3>Rating guide</h3>
        <dl>
          <dt>0-3</dt>
          <dd>
            = Dead 💀 - Park Baller <small>(working on it)</small>
          </dd>
          <dt>4</dt>
          <dd>= Bang Average</dd>
          <dt>5</dt>
          <dd>= Decent</dd>
          <dt>6</dt>
          <dd>= Sunday League Baller</dd>
          <dt>7</dt>
          <dd>= Different Gravy</dd>
          <dt>8</dt>
          <dd>= Special Talent</dd>
          <dt>9</dt>
          <dd>= Elite</dd>
          <dt>10</dt>
          <dd>= World Class 💫</dd>
        </dl>
      </Styled.Scoring>
      <Styled.PlayerRatings>
        {RatingStats.map((stat) => (
          <Styled.RatingGroup key={stat}>
            <label>
              {stat}
              {totalRating[stat] ? (
                <span>{totalRating[stat].avg?.toFixed(2)}</span>
              ) : null}
            </label>
            <RatingInput
              initialRating={rating[stat] ?? 0}
              readonly={isReadOnly}
              onChange={(value: number) =>
                setRating({ ...rating, [stat]: value })
              }
            />
          </Styled.RatingGroup>
        ))}
        {!isReadOnly && (
          <Styled.FormActions>
            <Button onClick={submitRating}>Submit</Button>
            <Button
              style={{ backgroundColor: 'transparent', color: '#ffffff' }}
              onClick={() => (window.location.href = window.location.origin)}
            >
              Cancel
            </Button>
          </Styled.FormActions>
        )}
      </Styled.PlayerRatings>

      <Modal
        onClose={() => setShowModal(false)}
        isOpen={showModal}
        contentStyle={{ height: '100%' }}
      >
        <Styled.ModalWrapper>
          <img className="logo" src="/images/logo_footify.svg" width={130} />
          <img src="/images/round_check.svg" />
          <br />
          <h4>Thank you. Your rating has been submitted.</h4>
          <p>
            <strong>What’s next?</strong>
            <br />
            Create your football profile, get your skills rated and more. Help
            us as we create the future for grassroots football.
          </p>
          <p className="download-app">
            <strong>Download Footify</strong>
            <a
              href="https://testflight.apple.com/join/0wKIBYLQ"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/logo_app_store.png" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=club.wearefootify.app"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/images/logo_google_play.png" />
            </a>
          </p>
          <small>
            <strong>Find out more:</strong>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://wearefootify.club"
            >
              https://wearefootify.club
            </a>
          </small>

          <button
            onClick={() => {
              setShowModal(false);
              window.location.href = 'https://wearefootify.club';
            }}
          >
            <img src="/images/ico_close_circle.svg" />
          </button>
        </Styled.ModalWrapper>
      </Modal>
    </Styled.Wrapper>
  ) : (
    <Styled.Wrapper {...props}>
      <Styled.Loader />
    </Styled.Wrapper>
  );
};
