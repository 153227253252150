import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { UserProfile } from 'shared/types/UserProfile';
import { MatchEvent } from 'shared/types/MatchEvent';
import { ChoosePeriod } from './CooosePeriod';
import { ChooseOption } from './ChooseOption';
import { EventTime } from './EventTime';
import { PlayerSelect } from './PlayerSelect';
import { PenaltyReason } from './PenaltyReason';
import { PenaltiesShootout } from './PenaltiesShootout';

const steps = [
  'choosePeriod',
  'chooseType',
  'penaltyReason',
  'eventTime',
  'givenAwayBy',
  'wonBy',
  'penaltyTaker',
  'scoredBy',
  'assistedBy',
  'penaltiesShootout',
  'penaltiesShootoutTaker',
  'penaltiesShootoutOutcome',
  'done',
] as const;

const emptyPlayer = {
  fullName: null,
  position: null,
  userId: null,
  profileImagePath: null,
};

export const AddEvent: React.FC<{
  event: MatchEvent;
  team: any[];
  oppositeTeam: any[];
  onCancel: () => void;
  onDone: (event: MatchEvent | null) => void;
}> = ({ event: _event, team, oppositeTeam, onCancel, onDone }) => {
  const [event, setEvent] = useState<Partial<MatchEvent> | null>({
    ..._event,
    player1: emptyPlayer,
    player2: emptyPlayer,
    player3: emptyPlayer,
  });

  const [activeStep, setActiveStep] =
    useState<typeof steps[number]>('choosePeriod');

  useEffect(() => {
    activeStep === 'done' && onDone(event as any);
  }, [activeStep]);

  const handlePeriodSelected = (matchPeriod: any): void => {
    setEvent({ ...event, matchPeriod });
    setActiveStep(
      matchPeriod === 'Penalty shootout' ? 'penaltiesShootout' : 'chooseType'
    );
  };

  const handleTypeSelected = (type: any): void => {
    setEvent({ ...event, type: type.toLowerCase() });
    setActiveStep('eventTime');
  };

  const handleReasonSelected = (penaltyReason: any): void => {
    setEvent({ ...event, penaltyReason });
    setActiveStep('givenAwayBy');
  };

  const handleTimeSelected = (timeString: any): void => {
    setEvent({ ...event, time: timeString });
    setActiveStep(event?.type === 'goal' ? 'scoredBy' : 'penaltyReason');
  };

  const handleSelectPlayer =
    (
      player: 'player1' | 'player2' | 'player3',
      nextStep: typeof steps[number]
    ) =>
    (profile: UserProfile | 'owngoal' | null): void => {
      if (profile === 'owngoal') {
        setEvent({ ...event, type: 'owngoal' });
      } else if (profile !== null) {
        setEvent({
          ...event,
          [player]: {
            fullName: profile.fullName,
            position: profile.position,
            userId: profile.userId,
            profileImagePath: profile.profileImagePathThumbnail ?? null,
          },
        });
      }

      setActiveStep(nextStep);
    };

  const handleOutcomeSelected = (outcome: any): void => {
    onDone({ ...event, penaltyResult: outcome } as MatchEvent);
    setEvent(_event);
    setActiveStep('penaltiesShootout');
  };

  const stepToComponent = {
    choosePeriod: (
      <ChoosePeriod
        key="choosePeriod"
        title={`${event?.teamName} incident`}
        onSelect={handlePeriodSelected}
        onCancel={onCancel}
      />
    ),
    chooseType: (
      <ChooseOption
        key="chooseType"
        title={`${event?.teamName} incident`}
        onSelect={handleTypeSelected}
        onBack={() => setActiveStep('choosePeriod')}
      />
    ),
    penaltyReason: (
      <PenaltyReason
        key="penaltyReason"
        onSelect={handleReasonSelected}
        onBack={() => setActiveStep('chooseType')}
      />
    ),
    eventTime: (
      <EventTime
        key="selectTime"
        title={`${event?.teamName} - ${event?.matchPeriod}`}
        onBack={() => setActiveStep('chooseType')}
        onNext={handleTimeSelected}
      />
    ),
    givenAwayBy: (
      <PlayerSelect
        key="givenAwayBy"
        title="Given away by"
        team={event?.isHomeTeam ? oppositeTeam : team}
        onBack={() => setActiveStep('penaltyReason')}
        onSkip={() => handleSelectPlayer('player1', 'wonBy')(null)}
        onPlayerSelect={handleSelectPlayer('player1', 'wonBy')}
      />
    ),
    wonBy: (
      <PlayerSelect
        key="wonBy"
        title="Won by"
        team={event?.isHomeTeam ? team : oppositeTeam}
        onBack={() => setActiveStep('givenAwayBy')}
        onSkip={() => handleSelectPlayer('player2', 'penaltyTaker')(null)}
        onPlayerSelect={handleSelectPlayer('player2', 'penaltyTaker')}
      />
    ),
    penaltyTaker: (
      <PlayerSelect
        key="penaltyTaker"
        title="Penalty taker"
        team={event?.isHomeTeam ? team : oppositeTeam}
        onBack={() => setActiveStep('wonBy')}
        onSkip={() => handleSelectPlayer('player3', 'done')(null)}
        onPlayerSelect={handleSelectPlayer('player3', 'done')}
      />
    ),
    scoredBy: (
      <PlayerSelect
        key="scoredBy"
        title="Scored by"
        team={event?.isHomeTeam ? team : oppositeTeam}
        showOwnGoal={true}
        onBack={() => setActiveStep('chooseType')}
        onSkip={() => setActiveStep('scoredBy')}
        onPlayerSelect={handleSelectPlayer('player1', 'assistedBy')}
      />
    ),
    assistedBy: (
      <PlayerSelect
        key="assistedBy"
        team={
          (event?.isHomeTeam && event?.type !== 'owngoal') ||
          (!event?.isHomeTeam && event?.type === 'owngoal')
            ? team
            : oppositeTeam
        }
        title={event?.type === 'owngoal' ? 'Scored by' : 'Assisted by'}
        onBack={() => setActiveStep('scoredBy')}
        onSkip={() => handleSelectPlayer('player2', 'done')(null)}
        onPlayerSelect={handleSelectPlayer('player2', 'done')}
      />
    ),
    penaltiesShootout: (
      <PenaltiesShootout
        onAddPenalty={(data) => {
          setEvent({ ...event, ...data });
          setActiveStep('penaltiesShootoutTaker');
        }}
        onDone={() => {
          setEvent(null);
          setActiveStep('done');
        }}
      />
    ),
    penaltiesShootoutTaker: (
      <PlayerSelect
        title="Penalty taker"
        team={event?.isHomeTeam ? team : oppositeTeam}
        onPlayerSelect={handleSelectPlayer(
          'player1',
          'penaltiesShootoutOutcome'
        )}
        onSkip={() =>
          handleSelectPlayer('player1', 'penaltiesShootoutOutcome')(null)
        }
        onBack={() => setActiveStep('penaltiesShootout')}
      />
    ),
    penaltiesShootoutOutcome: (
      <ChooseOption
        key="penaltiesShootoutOutcome"
        title="Outcome"
        options={['GOAL', 'Saved', 'Missed']}
        onSelect={handleOutcomeSelected}
        onBack={() => setActiveStep('penaltiesShootoutTaker')}
      />
    ),
    done: null,
  };

  return (
    <div>
      <div style={{ display: 'flex', overflow: 'hidden' }}>
        <AnimatePresence initial={false}>
          {stepToComponent[activeStep]}
        </AnimatePresence>
      </div>
    </div>
  );
};

export const MotionWrapper: React.FC = ({ children }) => (
  <motion.div
    layout
    style={{ flex: '0 0 100%', height: '100%', maxWidth: '100%' }}
    initial={{ x: 300, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: -300, opacity: 0 }}
    transition={{ type: 'linear' }}
  >
    {children}
  </motion.div>
);
