import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';

export const BorderedButton = styled.button`
  background-color: transparent;
  border: 2px solid ${Colours.tertiaryText};
  border-radius: 20px;
  color: ${Colours.primaryText};
  cursor: pointer;
  line-height: 2rem;
  font-size: 0.9rem;
  padding: 0 1rem;
`;

export const IconButton = styled.button`
  all: unset;
  cursor: pointer;
  line-height: 0;
  background-color: transparent;
`;
