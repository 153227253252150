import React, { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Avatar = styled.div`
  border-radius: 4px;
  background: ${Colours.box} url('/images/squad-placeholder.svg') 50% 50%
    no-repeat;
  background-size: 80px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: none;
  }
`;

const Location = styled.span`
  color: ${Colours.secondaryText};
  font-weight: 400;
`;

export interface Props {
  location: string;
  name: string;
  onClick: MouseEventHandler<HTMLLIElement>;
  src?: string;
}

export const Squad: React.FC<Props> = ({
  src,
  name,
  location,
  onClick,
  ...props
}) => (
  <Wrapper onClick={onClick} {...props}>
    <Avatar>{src && <img src={src} />}</Avatar>
    <span>{name}</span>
    <Location>{location}</Location>
  </Wrapper>
);
