import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { AuthContext } from './AuthContext';
import { firebaseConfig } from '../../../firebase.config';
import axios from 'axios';

export interface Props {
  config: typeof firebaseConfig;
}

export const AuthProvider: React.FC<Props> = ({ config, children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isInitialising, setIsInitialising] = useState(true);
  const logout = (): Promise<void> => firebase.auth().signOut();

  useEffect(() => {
    const [key, idToken] = window.location.hash.split('=');
    window.sessionStorage.setItem('returnUrl', window.location.pathname);
    if (firebase.apps.length == 0) {
      firebase.initializeApp(config);
    }
    isInitialising && setIsInitialising(false);

    firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      user
        ? user.getIdToken().then((token) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            setToken(token);
            !user?.isAnonymous && setUser(user);
          })
        : firebase
            .auth()
            .signInAnonymously()
            .then((result) => result.user?.getIdToken())
            .then((token) => {
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;
              setToken(token ?? null);
            });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        emailVerified: user?.emailVerified == true,
        token,
        isInitialising,
        firebase: isInitialising ? null : firebase.app(),
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
