import axios, { AxiosResponse } from 'axios';
import { UserProfile } from 'shared/types/UserProfile';

const createAccount = (
  userProfile: Partial<UserProfile>
): Promise<AxiosResponse> => {
  return axios.post('/api/users', userProfile);
};

export default {
  createAccount,
};
