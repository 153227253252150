import React, { SVGAttributes } from 'react';

export const Logo: React.FC<SVGAttributes<any>> = ({ ...props }) => (
  <svg width="32" height="48" color="currentColor" {...props}>
    <defs>
      <clipPath id="b">
        <use href="#a" />
      </clipPath>
      <path d="M0 0h31.8v31.1H0V0z" id="a" />
    </defs>
    <path
      d="M15.7 0l-1.3 4h-4.3l3.5 2.6-1.3 4 3.4-2.4 3.5 2.5L18 6.6l3.5-2.5H17L15.7 0z"
      fill="#FFF"
    />
    <path d="M0 15.8h31.8v31.1H0V15.8z" fill="none" />
    <g clipPath="url(#b)" transform="translate(0 15.7)">
      <path
        d="M30 7.8l-2.2 3.6 4 4.4c-.1 3.7-1.5 7.1-3.8 9.8l-5.2-.3-2 5.2a16 16 0 01-9.6 0l-2-5.4-5.5.4A15.8 15.8 0 010 15.3l4-3.6-2-4.2A16 16 0 0111.5.1l4.2 3.2L20.5.1a16 16 0 019.4 7.7zM10.7 22h9.8l3.2-9.2-8.2-5.7-7.7 5.4 3 9.5z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
