import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';
import { Avatar } from 'shared/components/Avatar';
import { RatingValue } from 'shared/components/RatingValue';
import { UserProfile } from 'shared/types/UserProfile';
import { fetchProfile } from './api';
import ProfileStyles from './Profile.styles';
import Styled from './ProfileCard.styles';
import { RatingStats } from './RateMe';

const footMap = { Left: 'LF', Both: 'BF', Right: 'RF' };

const getAvgRating = (
  ratingData?: Record<string, { avg: number; count: number }>
): string => {
  if (!ratingData || (ratingData['Goalkeeper']?.count ?? 0) < 10) {
    return '?';
  }

  const values = Object.values(ratingData).map((i) => i?.avg ?? 0);
  const avg = values.reduce((acc, v) => acc + v, 0) / RatingStats.length;

  return avg.toFixed(1);
};

export const ProfileCard: React.FC<
  RouteComponentProps & { userHandle: string }
> = ({ userHandle, ...props }) => {
  const [userData, setUserData] = useState<UserProfile | null>(null);

  useEffect(() => {
    fetchProfile(userHandle).then((response) => setUserData(response.data));
  }, [userHandle]);

  const rating = getAvgRating(userData?.playerRating);

  return (
    <Styled.Wrapper>
      <Helmet>
        <title>{`Footify - profile card: ${userData?.fullName}`}</title>
        <meta
          property="og:title"
          content={`Footify - footballer card: ${userData?.fullName}`}
        />
        <meta
          property="og:image"
          itemProp="image"
          content={`https://api.footify.co/api/users/${userHandle}/profile-card`}
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <img src="/images/logo_footify.svg" height={22} />
      <Styled.Card>
        {userData ? (
          <>
            <Avatar
              size={128}
              position={userData.position}
              src={userData.profileImagePath}
            />
            <ProfileStyles.PlayerName>
              {userData.fullName}
            </ProfileStyles.PlayerName>
            <ProfileStyles.PlayingStyle>
              {userData.playingStyle}. {footMap[userData.preferredFoot]}
            </ProfileStyles.PlayingStyle>
            <Styled.PlayerScore>{rating}</Styled.PlayerScore>
            <Styled.RatingGrid>
              <RatingValue
                label="GK"
                color="#065af7"
                value={userData.playerRating['Goalkeeper']?.avg.toFixed(1) ?? 0}
              />
              <RatingValue
                label="DEF"
                color="#06ef83"
                value={userData.playerRating['Defender']?.avg.toFixed(1) ?? 0}
              />
              <RatingValue
                label="MID"
                color="#f2d33d"
                value={userData.playerRating['Midfielder']?.avg.toFixed(1) ?? 0}
              />
              <RatingValue
                label="ATT"
                color="#f41043"
                value={userData.playerRating['Attacker']?.avg.toFixed(1) ?? 0}
              />
            </Styled.RatingGrid>
          </>
        ) : (
          'Loading...'
        )}
      </Styled.Card>
      <Styled.Footer>
        <a href="https://wearefootify.club">wearefootify.club</a>
        <img src="/images/logo_emblem.svg" />
      </Styled.Footer>
    </Styled.Wrapper>
  );
};
