import styled from 'styled-components/macro';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow-y: scroll;

  form {
    width: 500px;
  }

  h6 {
    margin-top: 3rem;
  }
`;

const Header = styled.div`
  display: flex;

  h1 {
    margin: 50px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5rem 0 3rem;
`;

export default {
  Page,
  Header,
  Controls,
};
