import styled from 'styled-components/macro';
import { SearchBar as _SearchBar } from 'shared/components/search-bar/SearchBar';
import { device } from 'shared/styles/MediaQuery';
import { Colours } from 'shared/styles/Colours';
import { Loader as _Loader } from 'shared/icons/loader';
import { Button } from 'shared/components/buttons/Button';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 5rem;
`;

const Wrapper = styled.div`
  width: min(900px, calc(100vw - 2rem));
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  & > h6 {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;
    flex: 1 0 auto;
  }
`;

const SearchBar = styled(_SearchBar)`
  max-width: 0;
  visibility: hidden;

  ${device.tablet} {
    visibility: visible;
    max-width: 660px;
  }
`;

const SquadName = styled.h3`
  margin: 2rem 0 0;
`;

const SquadLocation = styled.p`
  color: ${Colours.secondaryText};
  margin: 0 0 0.5rem;
`;

const Badges = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

const Matches = styled.ul`
  align-self: stretch;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(2, 285px);
  grid-row-gap: 2.25rem;
  grid-auto-columns: min(434px, 100%);
  padding: 0;

  ${device.tablet} {
    grid-auto-flow: column;
    grid-auto-rows: 285px;
    grid-column-gap: 2.25rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
    width: auto;
  }
`;

const TopGoalScorers = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;

    .goals {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .name {
      margin-top: 1rem;
    }

    .style {
      color: ${Colours.secondaryText};
    }
  }
`;

const Stats = styled.ul`
  align-self: stretch;
  display: grid;
  grid-auto-rows: 140px;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(2, min(45%, 202px));
  grid-row-gap: 2rem;

  ${device.tablet} {
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Players = styled.ul`
  align-self: stretch;
  display: grid;
  grid-auto-rows: 46px;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 2rem;

  ${device.tablet} {
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    border-bottom: 1px solid ${Colours.divider};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
    position: relative;

    &:after {
      content: url('data:image/svg+xml; utf8,
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none"  xmlns="http://www.w3.org/2000/svg" >
          <path 
            fill-rule="evenodd" 
            clip-rule="evenodd"
            fill="white" 
            d="M2.24005 0.347462C1.72698 -0.118037 0.902329 -0.115681 0.392689 0.353752C-0.116098 0.822391 -0.120382 1.5812 0.384153 2.05455L6.83376 7.99682L0.384153 13.9375C0.138293 14.1639 0 14.4714 0 14.7914C0 15.1123 0.138296 15.4197 0.384153 15.6462C0.896372 16.1179 1.72784 16.1179 2.24006 15.6462L9.61585 8.84998C9.86171 8.62352 10 8.31608 10 7.99604C10 7.67521 9.8617 7.36777 9.61585 7.14131L2.24005 0.347462Z"
          />
        </svg>
      ');
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

    .name,
    .style {
      width: 100%;
      display: block;
    }

    .style {
      color: ${Colours.secondaryText};
      font-size: 0.8rem;
    }
  }
`;

const AcceptInvite = styled(Button)`
  margin-top: 1.5rem;
`;

const Loader = styled(_Loader)`
  margin: auto;
`;

export default {
  Page,
  Wrapper,
  SearchBar,
  SquadName,
  SquadLocation,
  Badges,
  AcceptInvite,
  Matches,
  TopGoalScorers,
  Stats,
  Players,
  Loader,
};
