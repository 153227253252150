import { all, fork, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { UserProfile } from 'shared/types/UserProfile';
import loginActions from '../../log-in/store/loginActions';
import api from '../api';
import actions from './registerActions';
import { registrationDataSelector } from './registerSelectors';

function* createAccount(): Generator {
  try {
    const userProfile: any = yield select(registrationDataSelector);
    const response = yield api.createAccount(userProfile as UserProfile);
    yield put(actions.createAccount.success(response as any));
    yield put(
      loginActions.login.request({
        username: userProfile.email,
        password: userProfile.password,
      })
    );
  } catch (e) {
    yield put(actions.createAccount.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(actions.createAccount.request), createAccount);
}

export function* registerSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
