import React from 'react';
import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: ${Colours.primaryGrey};
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 12px;
  line-height: 2.6rem;
`;

const ValueBar = styled.div<{ value: number | string; color?: string }>`
  height: 4px;
  border-radius: 2px;
  background-color: ${Colours.primaryDark};
  position: relative;
  width: 100%;

  &:after {
    background-color: ${(props) => props.color ?? Colours.accent};
    content: ' ';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    width: ${(props) => Number(props.value) * 10}%;
  }
`;

export const RatingValue: React.FC<{
  label: string;
  value: number | string;
  color?: string;
}> = ({ value, label, color }) => {
  return (
    <Wrapper>
      <div>{label}</div>
      <div>{value}</div>
      <ValueBar value={value} color={color} />
    </Wrapper>
  );
};
