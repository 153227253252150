import React, { CSSProperties, MouseEvent, useRef } from 'react';
import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';
import { ModalPortal } from './ModalPortal';

const Overlay = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: #00000077;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export interface Props {
  anchorElement?: HTMLElement;
  overlayStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  onExitComplete?: () => void;
  onClose?: () => void;
  isOpen: boolean;
}

export const Modal: React.FC<Props> = ({
  anchorElement,
  overlayStyle = {},
  contentStyle = {},
  onClose,
  onExitComplete,
  isOpen,
  children,
  ...props
}) => {
  const overlayRef = useRef(null);

  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>): void => {
    if (onClose && event.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <ModalPortal anchorElement={anchorElement}>
      <AnimatePresence onExitComplete={onExitComplete}>
        {isOpen && (
          <Overlay
            onClick={handleOverlayClick}
            aria-modal="true"
            role="dialog"
            ref={overlayRef}
            style={overlayStyle}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Content
              style={contentStyle}
              initial={{ transform: 'translateY(-5%)' }}
              animate={{ transform: 'translateY(0%)' }}
              exit={{ transform: 'translateY(-5%)' }}
              {...props}
            >
              {children}
            </Content>
          </Overlay>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};
