import { Link as _Link } from '@reach/router';
import styled, { css } from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const Link = styled(_Link)`
  margin: 1rem 0;
  color: ${Colours.primaryText};
`;

const NotificationItem = styled.div<{ isRead?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${Colours.divider};
  color: ${Colours.secondaryText};
  display: flex;
  font-weight: 600;
  flex-wrap: wrap;
  padding: 1rem 0;
  position: relative;

  &::after {
    content: ${(props) => (props.isRead ? '' : "'\u00B7'")};
    display: inline-block;
    position: absolute;
    font-size: 4rem;
    line-height: 10px;
    width: 10px;
    color: ${Colours.accent};
    left: 0;
    top: 12px;
  }

  img {
    border-radius: 50%;
    width: 56px;
    height: 56px;
  }

  .message {
    padding-left: 1rem;
    font-size: 0.875rem;
    flex-basis: calc(100% - 66px);
  }

  .actions {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: calc(56px + 1rem);
    margin-top: 1rem;

    svg {
      cursor: pointer;
    }
  }

  .time {
    margin-left: 0.5em;
    font-weight: lighter;
  }
`;

export default {
  NotificationItem,
  Link,
};
