import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { propOr } from 'ramda';
import { Colours } from 'shared/styles/Colours';
import { Avatar } from 'shared/components/Avatar';
import { InfoBadge } from 'shared/components/InfoBadge';
import { StatsBadge } from 'shared/components/StatsBadge';
import { BorderedButton } from 'shared/components/Buttons';
import { MatchCard } from 'shared/components/match-card/MatchCard';
import { acceptSquadInvite, loadSquadProfile } from './store/squadActions';
import { squadSelector } from './store/squadSelectors';
import Styled from './SquadProfile.styles';

const SquadProfile: React.FC<RouteComponentProps & { ['*']?: string }> = ({
  '*': id,
  navigate,
  ...props
}) => {
  const squadProfile = useSelector(squadSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSquadProfile.request(id!));
  }, [id]);

  const handleSquadInviteAccept = (): void => {
    dispatch(acceptSquadInvite.request(squadProfile!.squadInvite!));
  };

  return squadProfile ? (
    <Styled.Page {...props}>
      <Styled.Wrapper>
        <Avatar
          size={120}
          src={squadProfile.squadAvatar || '/images/squad-placeholder.svg'}
        />
        <Styled.SquadName>{squadProfile.squadName}</Styled.SquadName>
        <Styled.SquadLocation>
          {squadProfile.squadLocation}
        </Styled.SquadLocation>

        <Styled.Badges>
          <InfoBadge
            title="Players"
            counter={squadProfile.players.length || 0}
          />
          <InfoBadge
            title="Supporters"
            counter={squadProfile.supporters.length}
          />
        </Styled.Badges>

        {squadProfile.squadInvite && (
          <Styled.AcceptInvite
            color={Colours.accent}
            textColor={Colours.primaryText}
            onClick={handleSquadInviteAccept}
          >
            Accept Invite
          </Styled.AcceptInvite>
        )}

        <h6>
          <span>Matches</span>
          <BorderedButton>View All</BorderedButton>
        </h6>
        <Styled.Matches>
          {squadProfile.matches.length ? (
            squadProfile.matches.map((match) => (
              <Link to={`/match/${match.id}`} key={match.id}>
                <MatchCard key={match.startAt._seconds} match={match} />
              </Link>
            ))
          ) : (
            <h3>No matches played.</h3>
          )}
        </Styled.Matches>

        <h6>
          <span>Top 4 Goalscorers</span>
          <BorderedButton>View All</BorderedButton>
        </h6>
        <Styled.TopGoalScorers>
          {squadProfile?.topScorers.map((player) => (
            <li key={player.userId}>
              <span className="goals">{player.goals}</span>
              <Avatar
                src={player.avatar || '/images/user-placeholder.svg'}
                position={player.position}
              />
              <span className="name">{player.fullName}</span>
              <span className="style">{player.playingStyle}</span>
            </li>
          ))}
        </Styled.TopGoalScorers>

        <h6>
          <span>Matches</span>
          <BorderedButton>View</BorderedButton>
        </h6>
        <Styled.Stats>
          {Object.entries({
            played: 'Played',
            won: 'Won',
            drawn: 'Drawn',
            lost: 'Lost',
          }).map(([itemName, title]) => (
            <StatsBadge
              key={itemName}
              counter={propOr(0)(itemName, squadProfile.stats)}
              title={title}
            />
          ))}
        </Styled.Stats>

        <h6>
          <span>Stats</span>
        </h6>
        <Styled.Stats>
          {Object.entries({
            scored: 'Goals',
            conceded: 'Conceded',
            cleansheet: 'Clean sheets',
          }).map(([itemName, title]) => (
            <StatsBadge
              key={itemName}
              counter={propOr(0)(itemName, squadProfile.stats)}
              title={title}
            />
          ))}
        </Styled.Stats>

        <h6>
          <span>{squadProfile?.players.length} Squad</span>
          <BorderedButton>View All</BorderedButton>
        </h6>
        <Styled.Players>
          {squadProfile?.players.map((player) => (
            <li key={player.userId}>
              <span className="name">{player.fullName}</span>
              <span className="style">
                {player.playingStyle} {player.preferredFoot} footed
              </span>
            </li>
          ))}
        </Styled.Players>
      </Styled.Wrapper>
    </Styled.Page>
  ) : (
    <Styled.Page {...props}>
      <Styled.Loader />
    </Styled.Page>
  );
};

export default SquadProfile;
