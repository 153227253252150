import React from 'react';
import axios from 'axios';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { Logo } from '../../icons/logo';
import { IcoThunder } from '../../icons/lightning';
import { IcoBell } from '../../icons/bell';
import { IcoMenu } from '../../icons/menu';
import { SearchResult } from '../../types/SearchResult';
import uiActions from '../../../store/uiActions';
import Styled from './Navbar.styles';

export interface Props {
  notifications: number;
}

export const Navbar: React.FC<Props> = ({
  notifications = 0,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const queryResolver = (query: string): Promise<SearchResult> =>
    axios
      .get<SearchResult>(`/api/search?query=${query}`)
      .then((response) => response.data);

  const onSearchItemClick = (item: any): void => {
    if (item.type === 'player') {
      navigate && navigate(`/profile/${item.playerUrl || item.id}`);
    } else {
      navigate && navigate(`/squad/${item.squadUrl || item.id}`);
    }
  };

  const handleMenuToggle = (): void => {
    dispatch(uiActions.sidebarChange({ component: 'menu' }));
  };

  const handleNotificationsToggle = (): void => {
    dispatch(uiActions.sidebarChange({ component: 'notifications' }));
  };

  return (
    <>
      <Styled.Wrapper {...props}>
        <Styled.HomeLink to={'/'}>
          <Logo />
        </Styled.HomeLink>

        <Styled.IcoSearch />

        <Styled.SearchBar
          onClick={onSearchItemClick}
          queryResolver={queryResolver}
        />

        <Styled.NewsFeedLink
          href="https://footify-curated.webflow.io/"
          target="_blank"
        >
          <IcoThunder />
        </Styled.NewsFeedLink>

        <Styled.Notifications
          count={notifications}
          onClick={handleNotificationsToggle}
        >
          <IcoBell />
        </Styled.Notifications>

        <Styled.MenuLink onClick={handleMenuToggle}>
          <IcoMenu />
        </Styled.MenuLink>
      </Styled.Wrapper>
      {children}
    </>
  );
};
