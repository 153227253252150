import React from 'react';

export const IcoErrorFace: React.FC = (props) => (
  <svg width="20px" height="20px" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="currentColor" strokeWidth={2} cx={12} cy={12} r={11} />
      <g transform="translate(6 6.75)" fill="currentColor">
        <circle cx={1.875} cy={1.875} r={1.875} />
        <circle cx={10.125} cy={1.875} r={1.875} />
      </g>
      <circle
        stroke="currentColor"
        strokeWidth={2}
        fill="currentColor"
        cx={12}
        cy={15.75}
        r={3}
      />
    </g>
  </svg>
);
