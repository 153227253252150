import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import { SearchBar as _SearchBar } from 'shared/components/search-bar/SearchBar';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';

const Wrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem;
  overflow: hidden auto;

  form {
    margin-top: 30%;
  }

  h5 {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${Colours.subheader};
    margin-top: 1rem;
    text-align: center;
  }

  main {
    margin-top: 15%;
    width: 560px;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colours.divider};
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;

  label {
    font-size: 14px;
  }
`;

const NavBar = styled.div<{ border?: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: baseline;
  border-bottom: ${({ border }) => (border ? '1px' : 0)} solid
    ${Colours.divider};
  margin-bottom: 1rem;

  .title {
    flex: 1;
    text-align: center;

    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const SidebarWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const ButtonSettings = styled.button<{ hasSettings: boolean }>`
  all: unset;
  border-bottom: 1px solid #363636;
  box-sizing: border-box;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1.3rem 2rem 1.3rem 0;
  position: relative;
  transition: border-bottom-color 150ms cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border-bottom-color: ${Colours.primaryText};
  }

  small {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;

    span {
      margin-right: 0.2rem;
    }
  }

  label {
    position: relative;
    color: ${({ hasSettings }) =>
      hasSettings ? Colours.secondaryText : Colours.primaryText};
    font-size: ${({ hasSettings }) => (hasSettings ? '.75rem' : '0.9rem')};
    top: ${({ hasSettings }) => (hasSettings ? '-8px' : 0)};
  }

  svg {
    position: absolute;
    right: 0;
    top: 45%;
    transform: rotate(180deg);
  }
`;

const Lineup = styled.main`
  padding: 0 1.5rem;
  overflow: hidden auto;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 120px 1fr;

  .vs {
    background-color: ${Colours.divider};
    text-align: center;
    line-height: 4rem;
    width: 4rem;
    border-radius: 50%;
    font-weight: bold;
    margin: 0 auto;
    position: sticky;
    top: 0;
  }

  .squad-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colours.darkGrey};
    position: sticky;
    top: 0;
    z-index: 1;

    h5 {
      font-size: 1rem;
      text-align: center;
      border-bottom: 1px solid ${Colours.primaryDark};
      padding-bottom: 1rem;
    }
  }

  button.add {
    all: unset;
    text-align: center;
    font-size: 1.4rem;
    background-color: ${Colours.accent};
    width: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const Player = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;

  .full-name {
    margin-top: 1rem;
  }

  .playing-style {
    color: ${Colours.secondaryText};
  }
`;

const SearchBar = styled(_SearchBar)`
  height: 40px;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;
  margin: 1rem 0 2rem;

  input {
    margin-left: 3px;
  }
`;

const SquadName = styled.input`
  all: unset;
  background-color: ${Colours.tertiaryText};
  border-bottom: 1px solid ${Colours.divider};
  width: 100%;
  line-height: 3rem;
  margin-top: -1.1rem;
  margin-bottom: 1rem;

  &::placeholder {
    color: ${darken(0.3, Colours.primaryText)};
  }
`;

const SelectPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  ul {
    flex: 1;
    overflow: hidden auto;
    padding-right: 1rem;
  }
`;

const EventPlayersList = styled.ul`
  li {
    display: grid;
    grid-template-columns: 25px 90px 1fr;
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${Colours.divider};
    padding: 0.5rem 0;
    cursor: pointer;

    &:after {
      content: url('/images/chevron.svg');
      display: inline-block;
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
    }
  }

  .own-goal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 56px;
    height: 56px;
    margin: 0 1rem;
    border-radius: 50%;
    background-color: ${Colours.primaryDark};
  }

  img {
    margin: 0 1rem;
  }
`;

const PlayerSelect = styled.li`
  display: grid;
  grid-template-columns: 40px 72px 1fr 50px 50px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid ${Colours.divider};
  padding: 10px 0;
  margin: 10px 0;

  input[type='radio'] {
    all: unset;
    margin-left: auto;

    &[value='Sub']:after {
      content: 'Sub';
    }

    :after {
      cursor: pointer;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      font-weight: bold;
      font-size: 8px;
      content: 'Start';
      display: inline-block;
      border-radius: 50%;
      line-height: 2rem;
      width: 2.3rem;
      color: ${Colours.primaryText};
      border: 2px solid ${Colours.secondaryText};
    }

    &:checked:after {
      color: ${Colours.secondaryText};
      background-color: ${Colours.secondaryBackground};
      border-color: ${Colours.secondaryBackground};
    }
  }
`;

const ListOption = styled.li`
  padding: 1rem 0 1rem 1rem;
  border-bottom: 1px solid ${Colours.divider};
  position: relative;
  cursor: pointer;

  &:after {
    content: url('/images/chevron.svg');
    position: absolute;
    right: 1rem;
    top: calc(50% - 0.5rem);
  }
`;

const Duration = styled.div`
  font-size: 1.5rem;
  padding: 120px 0 0;
  text-align: center;

  ${device.tablet} {
    font-size: 2.6rem;
  }
`;

export default {
  Wrapper,
  SidebarWrapper,
  FieldWrapper,
  NavBar,
  ButtonSettings,
  Lineup,
  Player,
  SearchBar,
  SquadName,
  EventPlayersList,
  SelectPlayersWrapper,
  PlayerSelect,
  ListOption,
  Duration,
};
