import { createAsyncAction } from 'typesafe-actions';
import { Squad } from 'shared/types/Squad';

export const loadSquadProfile = createAsyncAction(
  'squad/LOAD_PROFILE',
  'squad/LOAD_PROFILE_SUCCESS',
  'squad/LOAD_PROFILE_FAILURE'
)<string, Squad, Error>();

export const acceptSquadInvite = createAsyncAction(
  'squad/ACCEPT_INVITE',
  'squad/ACCEPT_INVITE_SUCCESS',
  'squad/ACCEPT_INVITE_FAILURE'
)<string, string, Error>();

export const rejectSquadInvite = createAsyncAction(
  'squad/REJECT_INVITE',
  'squad/REJECT_INVITE_SUCCESS',
  'squad/REJECT_INVITE_FAILURE'
)<string, string, Error>();

export default {
  loadSquadProfile,
  acceptSquadInvite,
  rejectSquadInvite,
};
