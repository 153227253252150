import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { RadioInput } from 'shared/components/input/RadioInput';
import { SearchResult } from 'shared/types/SearchResult';
import { profileSelector } from '../profile/store/profileSelectors';
import Styled from './AddMatch.styles';
import { Header } from './Header';

export const SelectSquad: React.FC<{
  title: string;
  onCancel: () => void;
  onSquadSelect: ({ id, name }: { id: string; name: string }) => void;
}> = ({ onSquadSelect, onCancel, title }) => {
  const { squads = null, userId = null } = useSelector(profileSelector) ?? {};
  const [squad, setSquad] = useState<{ id: string; name: string } | null>(null);

  const queryResolver = (query: string): Promise<SearchResult> =>
    axios
      .get<SearchResult>(`/api/search?query=${query}`)
      .then((response) => response.data)
      .then((result) => result.filter((item) => item.type === 'squad'));

  return (
    <Styled.SidebarWrapper>
      <Header
        leftButtonTitle="Cancel"
        title={title}
        rightButtonTitle="Done"
        rightButtonColour="white"
        onLeftButtonClick={onCancel}
        onRightButtonClick={() => {
          squad?.id && squad?.name.length > 0 && onSquadSelect(squad);
        }}
        border={true}
      />
      <Styled.SearchBar
        placeholder="Squad search"
        queryResolver={queryResolver}
        onClick={({ id, name }) => onSquadSelect({ id, name })}
      />
      <RadioInput
        label="This squad is not on Footify"
        name="TeamName"
        onChange={() => setSquad({ id: '0', name: '' })}
      />
      {squad?.id === '0' && (
        <Styled.SquadName
          placeholder="Enter squad name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSquad({ id: squad.id, name: event.target.value })
          }
        />
      )}
      {squads
        ?.filter((squad) => squad.squadOrganiserId === userId)
        .map((squad) => (
          <RadioInput
            name="squadName"
            key={squad.squadId}
            label={squad.squadName}
            subtitle={squad.squadOrganiserName}
            onChange={() =>
              setSquad({ id: squad.squadId, name: squad.squadName })
            }
          />
        ))}
    </Styled.SidebarWrapper>
  );
};
