import React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { MatchEvent } from 'shared/types/MatchEvent';
import { IcoGoal } from 'shared/icons/goal';
import { IcoPenMissed } from 'shared/icons/pen-missed';
import { IcoPenSaved } from 'shared/icons/pen-saved';
import { Colours } from 'shared/styles/Colours';

dayjs.extend(duration);

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(30%, 200px) 60px 40px 60px minmax(30%, 200px);
  grid-row-gap: 20px;
  margin-bottom: 40px;
`;

const EventPlayers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;

  .assist {
    font-size: 0.8rem;
  }
`;

const EventType = styled.div`
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.2rem;
  }
`;

const EventTime = styled.div`
  background-color: white;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 40px;
  font-size: 1.1rem;
  height: 40px;

  &:empty {
    background-color: ${Colours.primaryDark};
  }
`;

export const Event: React.FC<{ event: MatchEvent }> = ({ event, ...props }) => {
  const time = dayjs.duration(Math.ceil(event.time), 'seconds');

  const eventIco = (event: MatchEvent): JSX.Element | string => {
    if (event.type === 'goal') {
      return <IcoGoal />;
    } else {
      switch (event.penaltyResult) {
        case 'GOAL':
          return <IcoGoal />;
        case 'Missed':
          return <IcoPenMissed />;
        case 'Saved':
          return <IcoPenSaved />;
      }
    }

    return event.type[0];
  };

  return (
    <Wrapper {...props}>
      <EventPlayers>
        {event.isHomeTeam ? (
          <>
            <span>{event.player1?.fullName ?? 'Mystery Player'}</span>
            <span className="assist">{event.player2?.fullName}</span>
            <span>{event.player3?.fullName}</span>
          </>
        ) : null}
      </EventPlayers>
      <EventType>{event.isHomeTeam ? eventIco(event) : null}</EventType>
      <EventTime>
        {time.minutes() > 0 ? <>{time.minutes()}&apos;</> : null}
      </EventTime>
      <EventType>{!event.isHomeTeam ? eventIco(event) : null}</EventType>
      <EventPlayers>
        {!event.isHomeTeam ? (
          <>
            <span>{event.player1?.fullName ?? 'Mystery Player'}</span>
            <span>{event.player2?.fullName}</span>
            <span>{event.player3?.fullName}</span>
          </>
        ) : null}
      </EventPlayers>
    </Wrapper>
  );
};
