import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import { MatchState } from './matchReducer';

export const matchStateSelector = (state: AppState): MatchState => state.match;

export const matchSelector = createSelector(
  matchStateSelector,
  (state: MatchState) =>
    state.selectedMatchId ? state.matches[state.selectedMatchId] : null
);
