import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';

const Wrapper = styled.div`
  background-color: ${Colours.darkGrey};
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Splash = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default {
  Wrapper,
  Splash,
};
