import React from 'react';

export const IcoStar: React.FC = (props) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
    <path
      d="M5.4276 0L4.14686 3.95736H0L3.35472 6.40334L2.07325 10.3614L5.4276 7.91509L8.78159 10.3614L7.50048 6.40334L10.8548 3.95736H6.70871L5.4276 0Z"
      fill="white"
    />
  </svg>
);
