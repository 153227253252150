import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex: 1;

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] {
    & + label {
      border-radius: 2em;
      border: 1px solid ${Colours.primaryElement};
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 2rem;
      margin-right: 1rem;
      padding: 0 20px;
    }

    &:checked + label {
      background-color: white;
      color: black;
    }
  }
`;

export default {
  Wraper,
  GroupWrapper,
};
