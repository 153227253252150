import React from 'react';
import { Button } from 'shared/components/buttons/Button';
import { Colours } from 'shared/styles/Colours';
import Styled from './AddMatch.styles';

export const Header: React.FC<{
  title?: string;
  border?: boolean;
  leftButtonTitle?: string | null;
  onLeftButtonClick?: () => void;
  rightButtonTitle?: string | null;
  onRightButtonClick?: () => void;
  rightButtonColour?: 'white' | 'blue';
}> = ({
  title = null,
  border = false,
  leftButtonTitle = 'Back',
  rightButtonTitle = 'Next',
  rightButtonColour = 'blue',
  onLeftButtonClick,
  onRightButtonClick,
}) => (
  <Styled.NavBar border={border}>
    {leftButtonTitle && (
      <Button
        small
        type="button"
        width="auto"
        color={Colours.modalBackground}
        textColor={Colours.primaryText}
        onClick={onLeftButtonClick}
      >
        {leftButtonTitle}
      </Button>
    )}
    <div className="title">{title}</div>
    {rightButtonTitle && (
      <Button
        small
        type="button"
        width="auto"
        color={
          rightButtonColour === 'blue' ? Colours.accent : Colours.primaryElement
        }
        textColor={
          rightButtonColour === 'blue'
            ? Colours.primaryText
            : Colours.tertiaryText
        }
        onClick={onRightButtonClick}
      >
        {rightButtonTitle}
      </Button>
    )}
  </Styled.NavBar>
);
