import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { fetchMatch } from '../api';
import actions from './matchActions';

function* loadMatch(action: AnyAction): Generator {
  try {
    const match: any = yield fetchMatch(action.payload).then(
      (response) => response.data
    );
    yield put(actions.loadMatch.success(match));
  } catch (e) {
    yield put(actions.loadMatch.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(actions.loadMatch.request), loadMatch);
}

export function* matchSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
