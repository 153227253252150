import styled from 'styled-components/macro';
import { Loader as _Loader } from 'shared/icons/loader';
import { Logo as _Logo } from 'shared/icons/logo';
import { Tabs as _Tabs } from 'shared/components/tabs/tabs';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  padding: 5% 1rem 0;

  ${device.tablet} {
    padding: 5% 0 0;
  }

  h5 {
    margin-bottom: 0;
  }
`;

const Loader = styled(_Loader)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Date = styled.div`
  color: ${Colours.secondaryText};
`;

const Duration = styled.div`
  font-size: 1.5rem;
  text-align: center;

  ${device.tablet} {
    font-size: 3rem;
  }
`;

const Logo = styled(_Logo)`
  flex: 0 0 auto;
  height: 47px;
  margin-bottom: 1rem;
`;

const Result = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  width: 650px;
  max-width: 100%;

  button {
    font-size: 0.8rem;
    margin: 0 0.75rem 0.75rem;
    width: auto;

    ${device.tablet} {
      font-size: 1rem;
      margin: 0 0.75rem 1.5rem;
    }
  }
`;

const Score = styled.div`
  align-items: center;
  font-size: 3rem;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  line-height: 2em;

  ${device.tablet} {
    font-size: 192px;
    line-height: 1.3em;
  }

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 1em;
  }
`;

const Tabs = styled(_Tabs)`
  flex: 1 0 auto;
  width: 550px;
  margin-top: 4rem;
  max-width: 100%;

  h5 {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    color: ${Colours.subheader};
    margin: 1rem 0;
    text-align: center;
  }
`;

const LineUpRow = styled.div`
  display: grid;
  grid-template-columns: minmax(20%, 200px) 80px minmax(20%, 200px);
  grid-row-gap: 20px;
  margin-bottom: 40px;
  justify-content: center;

  span {
    text-align: center;

    &.player-home {
      cursor: pointer;
      text-align: right;
    }

    &.player-away {
      cursor: pointer;
      text-align: left;
    }

    &.rating-home,
    &.rating-away {
      display: inline-block;
      border-radius: 50%;
      width: 2rem;
      line-height: 2rem;
      inset: 1rem;
      color: ${Colours.tertiaryText};
      background-color: ${Colours.secondaryBackground};
    }

    &.rating-home {
      margin-left: 1rem;
    }
    &.rating-away {
      margin-right: 1rem;
    }
  }
`;

export default {
  Wrapper,
  Logo,
  Loader,
  LineUpRow,
  Date,
  Duration,
  Result,
  Score,
  Tabs,
};
