import React from 'react';
import { compose, filter, prop, sortBy, zip } from 'ramda';
import { MatchEvent, MatchPeriods } from 'shared/types/MatchEvent';
import { IcoGoal } from 'shared/icons/goal';
import { IcoPenMissed } from 'shared/icons/pen-missed';
import { IcoPenSaved } from 'shared/icons/pen-saved';

export const ResultIcon = {
  GOAL: <IcoGoal />,
  Missed: <IcoPenMissed />,
  Saved: <IcoPenSaved />,
  '': null,
};

export const getEventsByPeriod = (
  events: MatchEvent[]
): Partial<{ [key in MatchPeriods]: any[] }> => {
  return {
    'Sudden death penalty shootout': zip(
      getHomeSDPens(events),
      getAwaySDPens(events)
    ),
    'Penalty shootout': zip(getHomePens(events), getAwayPens(events)),
    '2nd half extra time': [
      ...getEventsForPeriod('2nd half extra time injury time', events),
      ...getEventsForPeriod('2nd half extra time', events),
    ],
    '2nd half': [
      ...getEventsForPeriod('2nd half injury time', events),
      ...getEventsForPeriod('2nd half', events),
    ],
    '1st half extra time': [
      ...getEventsForPeriod('1st half extra time injury time', events),
      ...getEventsForPeriod('1st half extra time', events),
    ],
    '1st half': [
      ...getEventsForPeriod('1st half injury time', events),
      ...getEventsForPeriod('1st half', events),
    ],
  };
};

const getEventsForPeriod = (
  period: MatchPeriods,
  events: MatchEvent[]
): MatchEvent[] =>
  events
    .filter((event) => event.matchPeriod === period)
    .sort((a, b) => (a.time > b.time ? 1 : -1));

const getEventsSatisfying = (
  predicate: (i: MatchEvent) => boolean
): ((events: MatchEvent[]) => MatchEvent[]) =>
  compose<MatchEvent[], MatchEvent[], MatchEvent[]>(
    sortBy(prop('index')),
    filter(predicate)
  );

export const getHomePens = getEventsSatisfying(
  (e) => e.matchPeriod === 'Penalty shootout' && e.isHomeTeam
);

export const getAwayPens = getEventsSatisfying(
  (e) => e.matchPeriod === 'Penalty shootout' && !e.isHomeTeam
);

export const getHomeSDPens = getEventsSatisfying(
  (e) => e.matchPeriod === 'Sudden death penalty shootout' && e.isHomeTeam
);

export const getAwaySDPens = getEventsSatisfying(
  (e) => e.matchPeriod === 'Sudden death penalty shootout' && !e.isHomeTeam
);
