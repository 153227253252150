export enum Colours {
  darkGrey = '#0b1214',
  darkBluish = '#161F23',
  primaryDark = '#282c2e',
  primaryGrey = '#bcbcbc',
  accent = '#2721f5',

  secondaryBackground = '#f8f8f8',
  modalBackground = '#212323',
  primaryElement = '#f8f8f8',
  primaryText = '#ffffff',
  secondaryText = '#999999',
  tertiaryText = '#1b1d1e',

  divider = '#363636',
  box = '#0E1619',
  subheader = '#616161',
}
