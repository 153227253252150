import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { CreateMatch } from 'shared/types/CreateMatch';
import { MatchEvent } from 'shared/types/MatchEvent';
import {
  getHomePens,
  getHomeSDPens,
  getAwayPens,
  getAwaySDPens,
  ResultIcon,
} from '../../match/utils';
import { Header } from '../Header';

const PenaltiesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 14px 40px 14px 1fr;
  grid-auto-rows: 60px;
  grid-column-gap: 1rem;
  align-items: center;
  font-size: 0.9rem;

  .home-team {
    text-align: right;
  }

  .home-team,
  .away-team {
    cursor: pointer;
  }

  h5 {
    margin-top: 2rem;
    grid-column: 1 / span 5;
    text-align: center;
    color: ${Colours.secondaryText};
  }

  .team-separator {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${Colours.primaryDark};
    margin: 0 auto;
  }

  .team-name {
    position: sticky;
    top: 0;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PenaltiesShootout: React.FC<{
  onAddPenalty: (event: Partial<MatchEvent>) => void;
  onDone: () => void;
}> = ({ onAddPenalty, onDone = () => null }) => {
  const { getValues } = useFormContext<CreateMatch>();
  const {
    homeTeamName,
    awayTeamName,
    events,
    settings: { penalties, suddenDeathPens },
  } = getValues();

  const homePens = getHomePens(events);
  const awayPens = getAwayPens(events);
  const homeSDPens = getHomeSDPens(events);
  const awaySDPens = getAwaySDPens(events);

  const handleAddPenalty = (
    isHomeTeam: boolean,
    index: number,
    type: 'normal' | 'extra'
  ): void =>
    onAddPenalty({
      type: 'penalty',
      penaltyShoot: true,
      time: 0,
      penaltyShootType: type,
      matchPeriod:
        type === 'normal'
          ? 'Penalty shootout'
          : 'Sudden death penalty shootout',
      isHomeTeam,
      index,
    });

  return (
    <Wrapper>
      <Header
        border={true}
        title="Penalties"
        rightButtonTitle="Done"
        rightButtonColour="white"
        onRightButtonClick={onDone}
        leftButtonTitle={null}
      />
      <PenaltiesList>
        <span className="team-name right">{homeTeamName}</span>
        <span />
        <span className="team-name">{awayTeamName}</span>
        {penalties > 0 ? (
          <>
            <h5>
              Penalties
              <div>
                *<small>1st penalty taker</small>
              </div>
            </h5>
            {Array(penalties)
              .fill(null)
              .map((_, idx) => (
                <React.Fragment key={idx}>
                  {homePens[idx] ? (
                    <div
                      className="home-team"
                      onClick={() => handleAddPenalty(true, idx, 'normal')}
                    >
                      {homePens[idx].player1?.fullName ?? 'Mystery player'}
                    </div>
                  ) : (
                    <div
                      className="home-team"
                      onClick={() => handleAddPenalty(true, idx, 'normal')}
                    >
                      Add penalty
                    </div>
                  )}

                  {ResultIcon[homePens[idx]?.penaltyResult] ?? <span />}
                  <div className="team-separator" />
                  {ResultIcon[awayPens[idx]?.penaltyResult] ?? <span />}

                  {awayPens[idx] ? (
                    <div
                      className="away-team"
                      onClick={() => handleAddPenalty(false, idx, 'normal')}
                    >
                      {awayPens[idx].player1?.fullName ?? 'Mystery player'}
                    </div>
                  ) : (
                    <div
                      className="away-team"
                      onClick={() => handleAddPenalty(false, idx, 'normal')}
                    >
                      Add penalty
                    </div>
                  )}
                </React.Fragment>
              ))}
          </>
        ) : null}
        {suddenDeathPens > 0 ? (
          <>
            <h5>Sudden-Death Penalties</h5>
            {Array(suddenDeathPens)
              .fill(null)
              .map((_, idx) => (
                <React.Fragment key={idx}>
                  {homeSDPens[idx] ? (
                    <div
                      className="home-team"
                      onClick={() => handleAddPenalty(true, idx, 'extra')}
                    >
                      {homeSDPens[idx].player1?.fullName ?? 'Mystery player'}
                    </div>
                  ) : (
                    <span
                      className="home-team"
                      onClick={() => handleAddPenalty(true, idx, 'extra')}
                    >
                      Add penalty
                    </span>
                  )}

                  {ResultIcon[homeSDPens[idx]?.penaltyResult] ?? <span />}
                  <span className="team-separator" />
                  {ResultIcon[awaySDPens[idx]?.penaltyResult] ?? <span />}

                  {awaySDPens[idx] ? (
                    <div
                      className="away-team"
                      onClick={() => handleAddPenalty(false, idx, 'extra')}
                    >
                      {awaySDPens[idx].player1?.fullName ?? 'Mystery player'}
                    </div>
                  ) : (
                    <span
                      className="away-team"
                      onClick={() => handleAddPenalty(false, idx, 'extra')}
                    >
                      Add penalty
                    </span>
                  )}
                </React.Fragment>
              ))}
          </>
        ) : null}
      </PenaltiesList>
    </Wrapper>
  );
};
