import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { Loader as _Loader } from 'shared/icons/loader';

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  overflow: hidden auto;

  .logo {
    margin-bottom: 4rem;
  }
`;

const ModalWrapper = styled.div`
  overflow-y: auto;
  background-color: ${Colours.darkGrey};
  padding: 2rem 2rem 1rem;
  width: 100%;
  max-width: 380px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 100%;
  }

  h4 {
    font-weight: 500;
  }

  button {
    all: unset;
  }

  small {
    margin: 0 0 3rem 0;
    width: 100%;
    font-size: 14px;
  }

  .logo {
    margin-bottom: 4rem;
  }

  .download-app {
    margin: 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    strong {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
`;

const Scoring = styled.div`
  width: 90%;
  max-width: 400px;
  border-radius: 12px;
  background-color: ${Colours.modalBackground};
  padding: 1rem;
  margin: 1.5rem;

  h3 {
    font-weight: bold;
  }

  dl {
    display: grid;
    grid-template-columns: 1.9rem 1fr;
    margin-bottom: 0;

    dd {
      margin: 0;
    }
  }
`;

const TextBright = styled.p`
  margin: 1rem 0 0;
  text-align: center;
  line-height: 2rem;
`;

const TextDark = styled.p`
  color: ${Colours.primaryGrey};
  margin: 0 0 0.5rem;
`;

const Loader = styled(_Loader)`
  margin: auto;
`;

const PlayerRatings = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    margin: 0 2px;
  }

  label {
    display: flex;
    justify-content: space-between;
    small {
      font-size: medium;
      font-weight: bold;
    }
  }
`;

const FormActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;

  button {
    margin: 0 auto 1rem;
    width: 192px;
    height: 56px;
    border-radius: 4px;
  }
`;

const RatingGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;

  label {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
`;

export default {
  Wrapper,
  ModalWrapper,
  TextBright,
  TextDark,
  Loader,
  RatingGroup,
  PlayerRatings,
  FormActions,
  Scoring,
};
