import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Notification, NotificationType } from '../../types/Notification';
import Styled from './NotificationItem.styles';

dayjs.extend(relativeTime);

export interface Props {
  notification: Notification;
}

export const NotificationItem: React.FC<Props> = ({
  notification,
  children,
  ...props
}) => {
  const getContent = (): JSX.Element => {
    switch (notification.type) {
      case NotificationType.SQUAD_INVITE_RCV:
        return (
          <>
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>{' '}
            would like you to join their team.
          </>
        );

      case NotificationType.USER_JOINED_SQUAD:
        return (
          <>
            Great news,{' '}
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            has accepted your squad invite to join{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.USER_JOINED_SQUAD_BC:
        return (
          <>
            Great news,{' '}
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            has accepted squad invite to join{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.NEW_SQUAD_SUPPORTER:
        return (
          <>
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            is now supporting{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.SQUAD_ARCHIVED:
        return (
          <>
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>{' '}
            has been archived by{' '}
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.SQUAD_INVITE_SNT:
        return (
          <>
            Invite to join{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>{' '}
            sent to{' '}
            <Styled.Link to={`/profile/${notification.recipientId}`}>
              {notification.recipientName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.NEW_FOLLOWER:
        return (
          <>
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            is following you.
          </>
        );

      case NotificationType.OPPONENT_ACCEPTED_FIXTURE:
        return <>Opponent has accepted your invite to play a Fixture.</>;

      case NotificationType.FIXTURE_USER_ADDED:
        return (
          <>
            User{' '}
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            added you to play a fixture in a team{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.FIXTURE_INVITE_RECEIVED:
        return (
          <>
            User
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            invited you to play{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      case NotificationType.USER_ACCEPTED_FIXTURE:
        return (
          <>
            You have accepted an invite from{' '}
            <Styled.Link to={`/profile/${notification.senderId}`}>
              {notification.senderName}
            </Styled.Link>{' '}
            to play{' '}
            <Styled.Link to={`/squad/${notification.requestId}`}>
              {notification.teamName}
            </Styled.Link>
            .
          </>
        );

      default:
        return <span>{JSON.stringify(notification)}</span>;
    }
  };

  return (
    <Styled.NotificationItem isRead={notification.isRead} {...props}>
      <img
        src={notification.avatar}
        alt={notification.senderName + ' ' + notification.id}
      />
      <div className="message">
        {getContent()}
        <span className="time">{dayjs(notification.time).fromNow()}</span>
      </div>
      {children}
    </Styled.NotificationItem>
  );
};
