import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { Input } from 'shared/components/input/Input';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Modal } from 'shared/components/modal/Modal';
import { Position } from 'shared/components/position/Position';
import { UserProfile } from 'shared/types/UserProfile';
import { Loader } from 'shared/icons/loader';
import { playingStylesForPosition } from 'shared/types/PlayingStyles';
import { Button } from 'shared/components/buttons/Button';
import { ImageInput } from 'shared/components/input/ImageInput';
import { AppState } from '../../store/rootReducer';
import { Location } from '../register/Location/Location';
import { authdUserProfileSelector } from '../profile/store/profileSelectors';
import profileActions from '../profile/store/profileActions';
import { RadioList } from './RadioList';
import { RadioButtonGroup } from './RadioButtonGroup';
import { validationSchema } from './utils';
import Styled from './EditProfile.styles';

export const EditProfile: React.FC<RouteComponentProps> = () => {
  const profile = useSelector(authdUserProfileSelector);
  const isSaving = useSelector((state: AppState) => state.profile.isSaving);
  const dispatch = useDispatch();
  const [locationModalShows, setLocationModalShows] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Partial<UserProfile>>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(profile?.email)),
  });

  const watchPosition = watch('position', profile?.position);
  const playingPositions = watchPosition
    ? playingStylesForPosition(watchPosition)
    : [];

  const onSubmit = (values: Partial<UserProfile>): void => {
    dispatch(profileActions.saveProfile.request(values));
  };

  useEffect(() => {
    profile &&
      reset({
        profileImagePath: profile.profileImagePath,
        fullName: profile.fullName,
        email: profile.email,
        dob: dayjs(profile?.dob).format('YYYY-MM-DD'),
        location: profile.location,
        gender: profile.gender,
        playingStyle: profile.playingStyle,
        preferredFoot: profile.preferredFoot,
        position: profile.position,
      });
  }, [profile, reset]);

  if (!profile) {
    return (
      <Styled.Page>
        <Loader />
      </Styled.Page>
    );
  }

  return (
    <Styled.Page>
      <Styled.Header>
        <h1>Edit profile</h1>
      </Styled.Header>

      <Controller
        name="profileImageFile"
        control={control}
        render={({ field }) => (
          <ImageInput {...field} value={profile?.profileImagePath} />
        )}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => <Input label="Full name:" {...field} />}
        />
        <ValidationError message={(errors?.fullName as FieldError)?.message} />

        <Controller
          name="email"
          control={control}
          render={({ field }) => <Input label="Email:" {...field} />}
        />
        <ValidationError message={(errors?.email as FieldError)?.message} />

        <Controller
          name="dob"
          control={control}
          render={({ field }) => <Input label="DOB:" type="date" {...field} />}
        />
        <ValidationError message={(errors?.dob as FieldError)?.message} />

        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <Input
              label="Where are you based"
              autoComplete="off"
              onFocus={() => setLocationModalShows(true)}
              {...field}
            />
          )}
        />
        <ValidationError message={(errors?.location as FieldError)?.message} />

        <RadioButtonGroup
          title="Gender"
          value={profile.gender}
          options={['Male', 'Female']}
          {...register('gender')}
        />

        <Controller
          name="preferredFoot"
          control={control}
          defaultValue={profile.preferredFoot}
          render={({ field }) => (
            <RadioButtonGroup
              title="Preferred foot"
              options={['Left', 'Both', 'Right']}
              {...field}
            />
          )}
        />

        <h6>Preferred position</h6>
        <Controller
          name="position"
          control={control}
          defaultValue={profile.position}
          render={({ field }) => (
            <Position value={field.value} onChange={field.onChange} />
          )}
        />

        <Controller
          name="playingStyle"
          control={control}
          defaultValue={profile.playingStyle}
          render={({ field }) => (
            <RadioList
              title="Playing style:"
              options={playingPositions}
              {...field}
            />
          )}
        />

        <Styled.Controls>
          <Button type="button" onClick={() => window.history.back()}>
            Back
          </Button>
          <Button type="submit" disabled={isSaving || !isDirty}>
            Save
            {isSaving && <Loader />}
          </Button>
        </Styled.Controls>
      </form>

      <Modal
        onClose={() => setLocationModalShows(false)}
        isOpen={locationModalShows}
        contentStyle={{ height: '100%' }}
      >
        <Location
          onCancel={() => setLocationModalShows(false)}
          onLocationSelected={(location) => {
            setValue('location', location);
            setLocationModalShows(false);
          }}
        />
      </Modal>
    </Styled.Page>
  );
};
