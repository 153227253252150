import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const Tab = styled.div<{
  header: JSX.Element | string;
  decoration?: string;
}>`
  margin-top: 1rem;
  overflow: hidden auto;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TabTitle = styled.div<{ active: boolean; decoration?: string }>`
  background-color: ${Colours.darkGrey};
  border-bottom: ${({ active }) => Number(active)}px solid
    ${Colours.secondaryBackground};
  color: ${({ active }) =>
    active ? Colours.primaryText : Colours.secondaryText};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  font-size: 14px;
  justify-content: center;
  padding: 1rem 0;
  position: relative;
  text-align: center;

  span {
    font-size: 20px;
    font-weight: bold;
  }

  small {
    color: ${Colours.secondaryText};
    margin-top: 4px;
    font-size: 12px;
  }

  &:before {
    content: '${(props) => props.decoration}';
    font-size: 3rem;
    line-height: 1rem;
    position: absolute;
    top: 5px;
    left: 5px;
    color: ${Colours.accent};
  }

  &:not(:last-child) {
    margin-right: 7px;
  }
`;

export const Tabs: React.FC<{
  onTabSelected?: (index: number) => void;
  children: any;
}> = ({ onTabSelected, children: tabs, ...props }) => {
  const [active, setActive] = useState(0);
  const tabHeaders = React.Children.map(tabs, (tab: any) => {
    const { header, ...props } = tab.props;
    return { header, ...props };
  });

  useEffect(() => {
    onTabSelected && onTabSelected(active);
  }, [active]);

  const activeTabChildren = React.Children.toArray(tabs)[active];

  return (
    <Wrapper {...props}>
      <Header>
        {tabHeaders.map(({ header, ...props }: any, idx: number) => (
          <TabTitle
            key={idx}
            active={idx === active}
            onClick={() => setActive(idx)}
            {...props}
          >
            {header}
          </TabTitle>
        ))}
      </Header>
      {activeTabChildren}
    </Wrapper>
  );
};
