export enum Steps {
  Basic = 'Basic',
  Gender = 'Gender',
  Foot = 'Foot',
  Position = 'Position',
  Style = 'Style',
  // Location = 'Location',
  Avatar = 'Avatar',
  OurValues = 'OurValues',
}
