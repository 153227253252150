import React, { ReactNode } from 'react';
import Styled from './RadioInput.styles';

export const RadioInput: React.FC<{
  ico?: ReactNode;
  label: string;
  subtitle?: string;
  register?: any;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ ico = null, label, subtitle, register, ...props }) => {
  return (
    <Styled.Wrapper>
      {ico}
      <label>
        {label}
        <small>{subtitle}</small>
      </label>
      <input type="radio" {...register} {...props} />
    </Styled.Wrapper>
  );
};
