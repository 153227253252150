import React from 'react';

export enum NotificationType {
  SQUAD_INVITE_RCV = 'receive squad request',
  SQUAD_INVITE_SNT = 'sendSquadRequest',
  USER_JOINED_SQUAD = 'joinSquadbyPlayer',
  USER_JOINED_SQUAD_BC = 'joinSquadPlayerBroadcast',
  NEW_SQUAD_SUPPORTER = 'squadSuppoter',
  SQUAD_ARCHIVED = 'squadArchive',
  SQUAD_UNARCHIVED = 'squadUnArchive',
  OPPONENT_ACCEPTED_FIXTURE = 'opponentSquadAcceptedFixtureInvition',
  OPPONENT_REJECTED_FIXTURE = 'opponentSquadRejectedFixtureInvition',
  USER_ACCEPTED_FIXTURE = 'acceptedFixtureInvition',
  USER_REJECTED_FIXTURE = 'rejectFixtureInvition',
  NEW_FOLLOWER = 'followingNotification',
  FIXTURE_USER_ADDED = 'PlayerAddInLineUpFixtureMatch',
  FIXTURE_USER_REMOVED = 'PlayerRemoveInLineUpFixtureMatch',
  FIXTURE_INVITE_RECEIVED = 'receiveFixtureInvition',
  FIXTURE_INVITE_SUPPORTER = 'supporterFixtureInvition',
  FIXTURE_CANCELED = 'cancelFixtureMatch',
  FIXTURE_DATE_CHANGE = 'FixtureMatchDateEdit',
  FIXTURE_MATCH_CHANGE = 'FixtureMatchEdit',
  FIXTURE_SETTINGS_CHANGE = 'FixtureMatchSettingEdit',
}

export class Notification {
  constructor(
    public id: string,
    public requestId: string,
    public isRead: boolean,
    public time: number,
    public type: NotificationType,
    public category: 'general' | 'received' | 'sent',
    public senderId: string,
    public senderName: string,
    public senderAvatar: string,
    public teamName: string,
    public recipientId: string,
    public recipientName: string
  ) {}

  get avatar(): string {
    if (this.senderAvatar) {
      return this.senderAvatar;
    }

    return this.type === NotificationType.SQUAD_INVITE_RCV
      ? '/images/squad-placeholder.svg'
      : '/images/user-placeholder.svg';
  }

  static fromJson(json: any): Notification {
    // no idea what this is used for, need to dig into iOS code?
    // acceptRequest "3" <- ( Accept = "1", Reject = "2", NoAction = "3" )
    const toMS = String(json.notificationTime).length === 13 ? 1 : 1000;
    const time = Number.parseInt(json.notificationTime) * toMS;

    return new Notification(
      json.id,
      json.requestId,
      json.isRead,
      time,
      json.notificationType,
      json.category,
      json.senderId,
      json.senderName,
      json.senderProfile,
      json.requestName,
      json.playerId,
      json.playerName
    );
  }
}
