import { getType, Reducer } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { auth } from 'firebase';
import { mergeRight } from 'ramda';
import actions from './loginActions';

export interface LoginState {
  isFetching: boolean;
  loginError: { code: string; message: string } | null;
  passwordResetError: { code: string; message: string } | null;
  passwordResetStatus: 'pending' | 'success' | 'error' | null;
  credentials: auth.UserCredential | null;
}

export const initialLoginState: LoginState = {
  isFetching: false,
  loginError: null,
  passwordResetError: null,
  passwordResetStatus: null,
  credentials: null,
};

const profileReducer: Reducer<LoginState, AnyAction> = (
  state = initialLoginState,
  action: AnyAction
) => {
  const { type, payload } = action;
  const updateState = mergeRight(state);

  switch (type) {
    case getType(actions.login.request): {
      return updateState<Partial<LoginState>>({
        isFetching: true,
        loginError: null,
      });
    }

    case getType(actions.login.success): {
      return updateState<Partial<LoginState>>({
        isFetching: false,
        credentials: payload,
      });
    }

    case getType(actions.login.failure): {
      return updateState<Partial<LoginState>>({
        isFetching: false,
        loginError: payload,
      });
    }

    case getType(actions.resetPassword.request): {
      return updateState<Partial<LoginState>>({
        isFetching: true,
        passwordResetError: null,
        passwordResetStatus: 'pending',
      });
    }

    case getType(actions.resetPassword.success): {
      return updateState<Partial<LoginState>>({
        isFetching: false,
        passwordResetStatus: 'success',
      });
    }

    case getType(actions.resetPassword.failure): {
      return updateState<Partial<LoginState>>({
        isFetching: false,
        passwordResetError: payload,
        passwordResetStatus: 'error',
      });
    }

    case getType(actions.clearResetPasswordState): {
      return updateState<Partial<LoginState>>({
        passwordResetError: null,
        passwordResetStatus: null,
      });
    }

    default:
      return state;
  }
};

export default profileReducer;
