import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { Normalize } from 'styled-normalize';
import { AuthProvider } from 'shared/services/auth/AuthProvider';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { rootStore } from './store/rootStore';
import { firebaseConfig } from './firebase.config';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

ReactDOM.render(
  <Provider store={rootStore}>
    <AuthProvider config={firebaseConfig}>
      <Helmet />
      <Normalize />
      <Toaster
        position={'top-right'}
        toastOptions={{ style: { color: '#713200' } }}
      />
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
