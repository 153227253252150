import { all, fork } from 'redux-saga/effects';
import { profileSaga } from '../modules/profile/store/profileSaga';
import { matchSaga } from '../modules/match/store/matchSaga';
import { loginSaga } from '../modules/log-in/store/loginSaga';
import { registerSaga } from '../modules/register/store/registerSaga';
import { squadSaga } from '../modules/squad-profile/store/squadSaga';
import { uiSaga } from './uiSaga';

export default function* rootSaga(): Generator {
  yield all([
    fork(uiSaga),
    fork(profileSaga),
    fork(matchSaga),
    fork(loginSaga),
    fork(registerSaga),
    fork(squadSaga),
  ]);
}
