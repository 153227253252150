import React from 'react';
import _NumberFormat from 'react-number-format';
import styled from 'styled-components/macro';
import { lighten } from 'polished';
import { Colours } from '../../styles/Colours';
import { useFormContext } from 'react-hook-form';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colours.divider};
  padding: 1rem 0;
  width: 100%;
`;

const StepButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${Colours.secondaryBackground};
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  height: 1.2rem;
  justify-content: center;
  line-height: 2px;
  width: 1.24rem;
`;

const Label = styled.label<{ isSmall: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
  font-size: ${({ isSmall }) => (isSmall ? '12px' : '14px')};
  padding-left: ${({ isSmall }) => (isSmall ? '1rem' : 0)};
  color: ${({ isSmall }) =>
    isSmall ? lighten(0.35, Colours.subheader) : 'inherit'};

  span {
    font-size: 0.8rem;
    color: ${Colours.subheader};
  }
`;

const NumberFormat = styled(_NumberFormat)`
  all: unset;
  background-color: ${Colours.darkGrey};
  border-radius: 4px;
  height: 40px;
  text-align: center;
  width: 65px;
  margin: 0 9px;
`;

export const NumberInput: React.FC<{
  label: string;
  name: string;
  subheading?: string;
  isSmall?: boolean;
}> = ({ label, name, subheading, isSmall = false, ...props }) => {
  const { setValue, watch } = useFormContext();
  const value = watch(name);
  return (
    <Wrapper {...props}>
      <Label isSmall={isSmall}>
        {label}
        <span>{subheading}</span>
      </Label>
      <StepButton onClick={() => setValue(name, value ? value - 1 : 0)}>
        &minus;
      </StepButton>
      <NumberFormat
        thousandSeparator={false}
        value={value}
        onValueChange={(event) => setValue(name, event.floatValue)}
      />
      <StepButton onClick={() => setValue(name, value ? value + 1 : 1)}>
        +
      </StepButton>
    </Wrapper>
  );
};
