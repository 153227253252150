import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from 'shared/components/Avatar';
import { InfoBadge } from 'shared/components/InfoBadge';
import { StatsBadge } from 'shared/components/StatsBadge';
import { BorderedButton } from 'shared/components/Buttons';
import { MatchCard } from 'shared/components/match-card/MatchCard';
import {
  acceptSquadInvite,
  loadSquadProfile,
} from '../squad-profile/store/squadActions';
import Styled from './PotmVoteInvite.styles';
import { profileSelector } from 'modules/profile/store/profileSelectors';
import profileActions from 'modules/profile/store/profileActions';
import { RatingValue } from 'shared/components/RatingValue';
import { Colours } from 'shared/styles/Colours';
import { matchSelector } from 'modules/match/store/matchSelectors';
import { loadMatch } from 'modules/match/store/matchActions';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import Countdown from 'react-countdown';

const PotmVoteInvite: React.FC<RouteComponentProps & { eventId: string }> = ({
  eventId,
  navigate,
  ...props
}) => {
  console.log('PotmVoteInvite eventId', eventId);
  const match = useSelector(matchSelector);
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match != null) {
      console.log('profile effect', match.organiserId);
      dispatch(profileActions.selectProfile(match!.organiserId));
      dispatch(profileActions.loadProfile.request(match!.organiserId));
    } else {
      console.log('match effect');
      dispatch(loadMatch.request(eventId));
    }
  }, [eventId, match]);

  const matchDate = (): string => {
    if (match == null) return '';
    const [hours, minutes] = match.kickoff
      ? match.kickoff.split(':')
      : ['0', '0'];

    const date =
      match.date +
      parseInt(hours) * 60 * 60 * 1000 +
      parseInt(minutes) * 60 * 1000;
    dayjs.extend(advancedFormat);
    return `${dayjs(date).format('dddd')} the
      ${dayjs(date).format('Do')} of 
      ${dayjs(date).format('MMMM')} at 
      ${dayjs(date).format('HH:mm')}`;
  };

  console.log('match', match);
  console.log('profile', profile);

  const renderer = ({ hours, minutes, seconds, completed }): any => {
    if (completed) {
      // Render a completed state
      return '';
    } else {
      // Render a countdown<span style={{color:"white"}}>

      if (hours < 9) {
        hours = '0' + hours;
      }
      if (minutes < 9) {
        minutes = '0' + minutes;
      }
      return (
        <span style={{ color: 'white' }}>
          {hours}:{minutes}
        </span>
      );
    }
  };
  return match && profile ? (
    <Styled.Page {...props}>
      <Styled.Wrapper>
        <img className="logo" src="/images/logo_footify.svg" width={177} />
        <div style={{ height: 117 }}></div>
        <Avatar
          size={64}
          position={profile.position}
          src={profile?.profileImagePath}
        />

        <Styled.PlayerContainer>
          <Styled.PlayerName>{profile?.fullName}</Styled.PlayerName>
          {/* <Styled.PlayerName style={{ color: Colours.secondaryText }}>
            @{profile?.playerUrl}
          </Styled.PlayerName> */}
          <Styled.PlayingStyle>
            {profile.playingStyle}. {profile.preferredFoot} footed
          </Styled.PlayingStyle>
        </Styled.PlayerContainer>

        <Styled.PlayerContainer>
          <Styled.PlayerName>
            {profile?.fullName} has invited you to vote for the Player Of The
            Match{' '}
          </Styled.PlayerName>
        </Styled.PlayerContainer>
        <Styled.MatchContainer>
          <Styled.SquadName>{match.title}</Styled.SquadName>
          <Styled.MatchDate>{matchDate()}</Styled.MatchDate>

          <div
            style={{
              alignItems: 'left',
              alignSelf: 'stretch',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                alignSelf: 'left',
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: 16,
                width: 31,
                height: 24,
                fontSize: 12,
                fontWeight: 'bold',
                marginTop: 24,
              }}
            >
              FT
            </div>
          </div>

          <Styled.ScoreContainer>
            <Styled.Score>{match.scoreHome}</Styled.Score>

            <Styled.SquadName> {match.homeSquadName}</Styled.SquadName>
          </Styled.ScoreContainer>

          <Styled.ScoreContainer>
            <Styled.Score>{match.scoreAway}</Styled.Score>

            <Styled.SquadName> {match.awaySquadName}</Styled.SquadName>
          </Styled.ScoreContainer>

          <Styled.VoteInfo>
            <img src="/images/potm-vote-logo.svg" width={32} height={32} />
            <div
              style={{
                alignSelf: 'center',
                fontSize: 12,
                fontWeight: 'bold',
                color: Colours.secondaryText,
                padding: 8,
                paddingRight: 0,
              }}
            >
              {' '}
              <Countdown
                date={match.potmSettings?.voteEndAt || Date.now()}
                renderer={renderer}
              />{' '}
              mins to POTM announcement
            </div>
          </Styled.VoteInfo>
        </Styled.MatchContainer>

        <Styled.InviteContent style={{ color: 'white', marginTop: 16 }}>
          To respond, please download the Footify app, create your profile and
          click the link again.
        </Styled.InviteContent>

        <p className="download-app" style={{ marginTop: 32 }}>
          <a
            href="https://testflight.apple.com/join/0wKIBYLQ"
            rel="noreferrer"
            target="_blank"
            style={{ marginRight: 16 }}
          >
            <img src="/images/logo_app_store.png" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=club.wearefootify.app"
            rel="noreferrer"
            target="_blank"
          >
            <img src="/images/logo_google_play.png" />
          </a>
        </p>

        <p
          style={{
            textAlign: 'center',
            marginTop: 32,
            fontSize: 14,
            color: 'white',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>What is Footify?</div>
          https://www.wearefootify.club
        </p>

        <img className="logo" src="/images/logo_emblem.svg" width={45} />
      </Styled.Wrapper>
    </Styled.Page>
  ) : (
    <Styled.Page {...props}>
      <Styled.Loader />
    </Styled.Page>
  );
};

export default PotmVoteInvite;
