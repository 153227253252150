import React from 'react';
import Switch from 'react-switch';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { NumberInput } from 'shared/components/input/NumberInput';
import { Colours } from 'shared/styles/Colours';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import Styled from './AddMatch.styles';

export const MatchSettings: React.FC = () => {
  const {
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const haveHalfTime = watch('settings.haveHalfTime');

  return (
    <Styled.Wrapper>
      <h5>Line up</h5>
      <NumberInput
        label="How many A-Side:"
        subheading="Starting line-up"
        name="settings.noPlayers"
      />
      <ValidationError
        message={(errors.settings?.noPlayers as FieldError)?.message}
      />

      <NumberInput label="Substitutes:" name="settings.substitutes" />
      <ValidationError
        message={(errors.settings?.substitutes as FieldError)?.message}
      />

      <NumberInput
        label="No. of substitutions:"
        subheading="Rolling"
        name="settings.noOfSubstitutions"
      />
      <ValidationError
        message={(errors.settings?.noOfSubstitutions as FieldError)?.message}
      />

      <h5 style={{ marginTop: '3rem' }}>Match duration and periods of play</h5>
      <Styled.FieldWrapper>
        <label>Play two halfs</label>
        <Controller
          name="settings.haveHalfTime"
          control={control}
          render={({ field }) => (
            <Switch
              onChange={(value) => setValue('settings.haveHalfTime', value)}
              checked={field.value}
              onColor={Colours.accent}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          )}
        />
      </Styled.FieldWrapper>

      <NumberInput label="Match length:" name="settings.length" />
      <ValidationError message={(errors.time as FieldError)?.message} />
      {haveHalfTime && (
        <>
          <NumberInput
            label="1st half injury time:"
            isSmall
            name="injuryTimeByPeriod.firstHalf"
          />
          <NumberInput
            label="2nd half injury time:"
            isSmall
            name="injuryTimeByPeriod.secondHalf"
          />
        </>
      )}
      <NumberInput label="Extra time:" name="settings.extraTime" />
      {haveHalfTime && (
        <>
          <NumberInput
            label="1st half injury time:"
            isSmall
            name="injuryTimeByPeriod.extraFirstHalf"
          />
          <NumberInput
            label="2nd half injury time:"
            isSmall
            name="injuryTimeByPeriod.extraSecondHalf"
          />
        </>
      )}
      <NumberInput label="Penalties:" name="settings.penalties" />
      <NumberInput
        label="Sudden death pens"
        isSmall
        name="settings.suddenDeathPens"
      />
    </Styled.Wrapper>
  );
};
