import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  fetchProfile,
  fetchUserNotifications,
  markAsRead,
} from '../modules/profile/api';
import uiActions from './uiActions';
import { UserProfile } from '../shared/types/UserProfile';
import profileActions from '../modules/profile/store/profileActions';

function* loadUserData(): Generator {
  try {
    const profile: UserProfile = yield fetchProfile('getByAuth').then(
      (response) => response.data
    );
    yield put(profileActions.loadProfile.success(profile));
    const notifications = yield fetchUserNotifications().then(
      (response) => response.data
    );
    yield put(uiActions.loadNotifications.success(notifications));
  } catch (e) {
    yield put(uiActions.loadNotifications.failure(e));
  }
}

function* markNotificationsRead(action: any): Generator {
  try {
    yield markAsRead(action.payload);
    yield put(uiActions.markNotificationsRead.success());
  } catch (e) {
    yield put(uiActions.markNotificationsRead.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(uiActions.userAuthStateChanged), loadUserData);
  yield takeLatest(
    getType(uiActions.markNotificationsRead.request),
    markNotificationsRead
  );
}

export function* uiSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
