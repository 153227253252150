import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Location } from '@reach/router';
import Login from 'modules/log-in/Login';
import Profile from 'modules/profile/Profile';
import Welcome from 'modules/register/Welcome/Welcome';
import Match from 'modules/match/Match';
import LiveMatch from 'modules/match/LiveMatch';
import SquadProfile from 'modules/squad-profile/SquadProfile';
import { Register } from 'modules/register/Register';
import { EditProfile } from '../modules/edit-profile/EditProfile';
import { userNotifications } from 'modules/profile/store/profileSelectors';
import { GlobalStyle } from 'shared/styles/GlobalStyle';
import { AuthContext } from 'shared/services/auth/AuthContext';
import { LogoLarge } from 'shared/icons/logo-large';
import { Sidebar } from 'shared/components/sidebar/sidebar';
import Menu from 'shared/components/sidebar/menu';
import Notifications from 'shared/components/sidebar/notifications';
import uiActions from '../store/uiActions';
import { AppState } from '../store/rootReducer';
import { Navbar } from '../shared/components/navbar/Navbar';
import { AppNotification } from './AppNotifications';
import Styled from './App.styles';
import { RatePlayers } from '../modules/rate-players/RatePlayers';
import { AddMatch } from '../modules/add-match/AddMatch';
import { RateMe } from '../modules/profile/RateMe';
import { ProfileCard } from '../modules/profile/ProfileCard';
import { ErrorBoundary } from '../shared/components/ErrorBoundary';
import SquadInvite from '../modules/squad-invite/SquadInvite';
import EventInvite from '../modules/event-invite/EventInvite';
import PotmVoteInvite from 'modules/potm-vote-invite/PotmVoteInvite';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state: AppState) => state.ui.sidebar);
  const notifications = useSelector(userNotifications);
  const { isInitialising, emailVerified, logout, user, token } =
    useContext(AuthContext);

  const handleSidebarToggle = (): void => {
    dispatch(uiActions.sidebarChange({ component: null, props: {} }));
  };

  const handleLogout = (): void => {
    handleSidebarToggle();
    logout();
  };

  const isAnonymous = (token: string | null): boolean => {
    const [, payload] = token?.split('.') || [null, null];
    return (
      Boolean(payload) && JSON.parse(atob(payload!)).provider_id === 'anonymous'
    );
  };

  const notificationsCount = notifications.filter(
    ({ isRead }) => isRead
  ).length;

  useEffect(() => {
    // wait for token before trying to load notifications
    if (token && !isAnonymous(token) && user)
      dispatch(uiActions.userAuthStateChanged(user));
  }, [token, user]);

  if (isInitialising || !token) {
    console.log('isInitialising || !token');
    return (
      <Styled.Wrapper>
        <GlobalStyle />
        <Styled.Splash>
          <LogoLarge />
        </Styled.Splash>
      </Styled.Wrapper>
    );
  } else if (isAnonymous(token)) {
    console.log('anonymous user visit');
    return (
      <Styled.Wrapper>
        <GlobalStyle />
        <Router role="router">
          <Login path="/" default />
          <ProfileCard path="/profile-card/:userHandle" />
          {/* <Register path="register/*" /> */}
          <LiveMatch path="/squad/:idOrName/live" />
          <RatePlayers path="/match/:matchId/player-rating" />
          <SquadProfile path="/squad/*" />
          <SquadInvite path="/invite/:inviteId/squad/:squadId" />
          <EventInvite path="/invite/:inviteId/event/:eventId" />
          <PotmVoteInvite path="/match/:eventId/potm-vote" />
          <RateMe path="/rate-me/:uid" />
          <Profile path="/profile/:uid" />
        </Router>
      </Styled.Wrapper>
    );
  } else if (user && emailVerified == false) {
    console.log('!emailVerified');
    return (
      <Styled.Wrapper>
        <GlobalStyle />
        <Welcome path="/" default />
      </Styled.Wrapper>
    );
  }

  console.log('logged-in user routes activated');
  return (
    <Styled.Wrapper>
      <GlobalStyle />
      <Sidebar onClose={handleSidebarToggle}>
        {sidebarState.component &&
          {
            menu: <Menu onLogout={handleLogout} />,
            notifications: <Notifications notifications={notifications} />,
            playerProfile: sidebarState.props && (
              <Profile
                uid={sidebarState.props.uid}
                showsInDrawer={sidebarState.props.showsInDrawer}
              />
            ),
          }[sidebarState.component]}
      </Sidebar>
      <GlobalStyle />
      <AppNotification />
      <Location>
        {({ location }) => {
          const showNav = !location.pathname.match(
            /add-match|rate-me|profile-card/
          );
          return (
            <ErrorBoundary>
              {showNav && <Navbar notifications={notificationsCount} />}
              <Router
                role="router"
                style={{
                  paddingTop: showNav ? '6rem' : 0,
                  overflow: 'hidden',
                }}
              >
                <Profile path="/" />
                <Profile path="/profile/:uid" />
                <ProfileCard path="/profile-card/:userHandle" />
                <RateMe path="/rate-me/:uid" />
                <EditProfile path="/edit-profile" />
                <SquadProfile path="/squad/*" />
                <SquadInvite path="/invite/:inviteId/squad/:squadId" />
                <EventInvite path="/invite/:inviteId/event/:eventId" />
                <RatePlayers path="/match/:matchId/player-rating" />
                <PotmVoteInvite path="/match/:eventId/potm-vote" />
                <Match path="/match/:id" />
                <LiveMatch path="/squad/:idOrName/live" />
                <AddMatch path="/add-match" />
              </Router>
            </ErrorBoundary>
          );
        }}
      </Location>
    </Styled.Wrapper>
  );
};

export default App;
