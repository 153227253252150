import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { IcoBack } from 'shared/icons/back';
import { Colours } from '../../styles/Colours';
import { IconButton } from '../Buttons';

const SidebarWrapper = styled.div<{ isVisible: boolean; width?: string }>`
  background-color: ${Colours.tertiaryText};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 360px;
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(${(props) => (props.isVisible ? '0%' : '100%')});
  transition: transform 300ms cubic-bezier(0.86, 0, 0.07, 1);
  width: ${({ width }) => (width ? width : '35%')};
  z-index: 100;
  overflow: hidden;

  ${IconButton} {
    transform: rotateY(180deg);
    margin-bottom: 4rem;
    margin-right: auto;
  }
`;

export const Sidebar: React.FC<{
  onClose?: () => void;
  header?: ReactNode;
  showsClose?: boolean;
  width?: string;
}> = ({ children, onClose, showsClose = true, header, width, ...props }) => {
  return (
    <SidebarWrapper
      width={width}
      isVisible={React.Children.count(children) !== 0}
      {...props}
    >
      {header}
      {showsClose ? (
        <IconButton onClick={onClose}>
          <IcoBack />
        </IconButton>
      ) : null}
      {children}
    </SidebarWrapper>
  );
};
