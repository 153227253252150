import React from 'react';
import Styled from '../AddMatch.styles';
import { MotionWrapper } from './AddEvent';
import { Header } from '../Header';
import styled from 'styled-components/macro';

const ListOption = styled(Styled.ListOption)`
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
`;

export const PenaltyReason: React.FC<{
  onSelect: (type: string) => void;
  onBack: () => void;
}> = ({ title, onSelect, onBack }) => (
  <MotionWrapper>
    <Header
      onLeftButtonClick={onBack}
      title="Reason for penalty"
      rightButtonTitle={null}
    />
    <ul>
      <ListOption onClick={() => onSelect('Foul')}>Foul</ListOption>
      <ListOption onClick={() => onSelect('Handball')}>Handball</ListOption>
      <ListOption onClick={() => onSelect('Other')}>Other</ListOption>
    </ul>
  </MotionWrapper>
);
