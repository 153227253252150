import { createSelector } from 'reselect';
import { AppState } from 'store/rootReducer';
import { SquadState } from './squadReducer';

export const squadStateSelector = (state: AppState): SquadState => state.squad;

export const squadSelector = createSelector(
  squadStateSelector,
  (state: SquadState) => state.squad
);
