import React, { useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { Sidebar } from 'shared/components/sidebar/sidebar';
import { Input } from 'shared/components/input/Input';
import { IcoBack } from 'shared/icons/back';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Button } from 'shared/components/buttons/Button';
import { Colours } from 'shared/styles/Colours';
import { MatchSettings } from './MatchSettings';
import Styled from './AddMatch.styles';

interface Props {
  onNext: () => void;
  onCancel: () => void;
}

export const Basics: React.FC<Props> = ({ onNext, onCancel }) => {
  const {
    register,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sidebarHeader = (
    <Styled.NavBar>
      <Button
        small
        type="button"
        color={Colours.modalBackground}
        textColor={Colours.primaryText}
        width="auto"
        onClick={() => setSidebarVisible(false)}
      >
        Cancel
      </Button>
      <div className="title">Match settings</div>
      <Button
        small
        type="button"
        width="auto"
        onClick={() =>
          trigger(['settings']).then((isValid) => {
            isValid && setSidebarVisible(false);
          })
        }
      >
        Done
      </Button>
    </Styled.NavBar>
  );

  const settings = watch('settings');
  const hasSettings = Object.values(settings).filter(Boolean).length > 0;

  return (
    <Styled.Wrapper
      initial={{ opacity: 0, transform: 'translateX(100%)' }}
      animate={{ opacity: 1, transform: 'translateX(0px)' }}
      exit={{ opacity: 0, transform: 'translateX(-100%)' }}
    >
      <Sidebar header={sidebarHeader} showsClose={false} width="400px">
        {sidebarVisible ? <MatchSettings /> : null}
      </Sidebar>

      <Styled.NavBar>
        <Button
          color={Colours.modalBackground}
          textColor={Colours.primaryText}
          width="auto"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <div className="title" />
        <Button
          type="button"
          color={Colours.accent}
          textColor={Colours.primaryText}
          width="auto"
          onClick={() =>
            trigger(['title', 'venue', 'date', 'time', 'settings']).then(
              (isValid) => {
                if (isValid) {
                  setValue('time', settings.length + settings.extraTime);
                  onNext();
                }
              }
            )
          }
        >
          Next
        </Button>
      </Styled.NavBar>

      <main>
        <Input label="Title" {...register('title')} tabIndex="1" />
        <ValidationError message={(errors.title as FieldError)?.message} />

        <Input label="Venue" {...register('venue')} tabIndex="2" />
        <ValidationError message={(errors.venue as FieldError)?.message} />

        <Input
          label="Date"
          type="date"
          {...register('settings.date')}
          tabIndex="3"
        />
        <ValidationError message={(errors.date as FieldError)?.message} />

        <Input
          label="Kick off"
          type="time"
          {...register('settings.time')}
          tabIndex="4"
        />
        <ValidationError message={(errors.startAt as FieldError)?.message} />

        <Styled.ButtonSettings
          hasSettings={hasSettings}
          onClick={() => setSidebarVisible(true)}
          onFocus={() => setSidebarVisible(true)}
          tabIndex="5"
        >
          <label>Match settings</label>
          {hasSettings && (
            <small>
              <span>{settings.noPlayers} A-side /</span>
              <span>{settings.substitutes} Subs /</span>
              <span>{settings.noOfSubstitutions} Substitutions /</span>
              <span>Normal time {settings.length}&lsquo; /</span>
              <span>Extra time {settings.extraTime}&lsquo; /</span>
              <span>Penalties {settings.penalties}</span>
            </small>
          )}
          <IcoBack />
        </Styled.ButtonSettings>
        <ValidationError
          message={errors.settings ? 'Please enter match settings' : null}
        />
      </main>
    </Styled.Wrapper>
  );
};
