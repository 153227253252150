import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SearchResult } from '../../types/SearchResult';
import { PlayingPosition } from '../../types/PlayingPosition';
import Styled from './SearchBar.styles';
import { Loader } from '../../icons/loader';

export interface Props {
  queryResolver: (q: string) => Promise<SearchResult>;
  onClick: (item: any) => void;
  placeholder?: string;
}

export const SearchBar: React.FC<Props> = ({
  onClick,
  queryResolver,
  placeholder = 'Player search',
  ...props
}) => {
  const [suggestions, setSuggestions] = useState<SearchResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [value] = useDebounce(search, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  };

  const handleClick = (item: any): void => {
    setSearch('');
    onClick(item);
  };

  const highlight = (name: string): (string | JSX.Element)[] =>
    name
      .split(RegExp(`(${value})`))
      .map((value, index) =>
        index % 2 === 0 ? value : <b key={index}>{value}</b>
      );

  useEffect(() => {
    setLoading(true);
    if (value) {
      queryResolver(value).then((result) => {
        setSuggestions(result);
        setLoading(false);
      });
    } else {
      setLoading(false);
      setSuggestions(null);
    }
  }, [value]);

  return (
    <Styled.Wrapper {...props}>
      {loading ? <Loader /> : <Styled.IcoSearch />}
      <input onChange={handleChange} value={search} placeholder={placeholder} />
      <Styled.RoundButton
        iconUrl="/images/close.svg"
        onClick={() => setSearch('')}
      />
      {suggestions && (
        <Styled.Suggestions>
          {suggestions.map((suggestion) => (
            <li key={suggestion.id} onClick={() => handleClick(suggestion)}>
              {suggestion.type === 'player' && suggestion.position && (
                <Styled.PositionBadge
                  position={suggestion.position as PlayingPosition}
                />
              )}
              <Styled.Avatar src={suggestion.avatar || undefined} />
              <span>{highlight(suggestion.name)}</span>
            </li>
          ))}
        </Styled.Suggestions>
      )}
    </Styled.Wrapper>
  );
};
