import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Tab } from 'shared/components/tabs/tabs';
import actions from './store/matchActions';
import { matchSelector } from './store/matchSelectors';
import { Event } from './components/Event';
import Styled from './Match.styles';
import { unfold, zip } from 'ramda';
import uiActions from '../../store/uiActions';
import { getEventsByPeriod } from './utils';
import { PenShootoutEvent } from './components/PenShootoutEvent';

dayjs.extend(duration);

const Match: React.FC<RouteComponentProps & { id?: string }> = ({
  navigate,
  id,
  ...props
}) => {
  const match = useSelector(matchSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(actions.loadMatch.request(id));
  }, [id]);

  if (match === null) {
    return <Styled.Loader />;
  }

  const matchDate = dayjs
    .unix(match.startAt._seconds)
    .format('MM ddd, MMM YYYY. HH:mm ');
  const duration = dayjs.duration(Math.ceil(match.time), 'seconds');

  const { homeTeamPlayers, awayTeamPlayers } = match;

  const maxTeamPlayers = Math.max(
    homeTeamPlayers.length,
    awayTeamPlayers.length
  );
  const fillBlanks =
    (team: any[]) =>
    (n: number): false | [any, number] =>
      n < maxTeamPlayers && [team[n] || null, n + 1];

  const lineUps = zip(
    unfold(fillBlanks(homeTeamPlayers), 0),
    unfold(fillBlanks(awayTeamPlayers), 0)
  );

  const handleProfileClick = (uid?: string): void => {
    uid &&
      dispatch(
        uiActions.sidebarChange({
          component: 'playerProfile',
          props: { uid, showsInDrawer: true },
        })
      );
  };

  const formatRanking = new Intl.NumberFormat('en-GB', {
    maximumSignificantDigits: 2,
  }).format;

  const eventsByPeriod = getEventsByPeriod(match.events || []);

  return (
    <Styled.Wrapper {...props}>
      <Styled.Logo />
      <h5>
        {match.title}{' '}
        {match.settings?.length ? `(${match.settings?.length}min)` : null}
      </h5>
      <Styled.Date>{matchDate}</Styled.Date>
      <Styled.Duration>
        {duration.hours() ? `${duration.hours()}` : 0}:{duration.minutes()}.
        {duration.seconds()}
      </Styled.Duration>
      <Styled.Result>
        <Styled.Score>
          {match.score0 ?? '0'}
          <span>{match.homeSquadName}</span>
        </Styled.Score>
        <span />
        <Styled.Score>
          {match.score1 ?? '0'}
          <span>{match.awaySquadName}</span>
        </Styled.Score>
      </Styled.Result>
      <Styled.Tabs>
        <Tab header={<span>Match Report</span>}>
          {Object.entries(eventsByPeriod).map(([period, periodEvents]) =>
            periodEvents!.length === 0 ? null : (
              <React.Fragment key={period}>
                <h5>{period}</h5>
                {periodEvents!.map((pev, idx) =>
                  period.includes('shootout') ? (
                    <PenShootoutEvent
                      key={idx}
                      eventHome={pev[0]}
                      eventAway={pev[1]}
                    />
                  ) : (
                    <Event key={idx} event={pev} />
                  )
                )}
              </React.Fragment>
            )
          )}
        </Tab>
        <Tab header={<span>Line-ups</span>}>
          {lineUps.map(([homePlayer, awayPlayer]) => (
            <Styled.LineUpRow key={homePlayer}>
              <span
                className="player-home"
                onClick={() => handleProfileClick(homePlayer?.userId)}
              >
                {homePlayer?.fullName}
                {homePlayer && match.ratingStatus === 'finished' ? (
                  <span className="rating-home">
                    {formatRanking(homePlayer.rating)}
                  </span>
                ) : null}
              </span>
              <span> - &nbsp; - </span>
              <span
                className="player-away"
                onClick={() => handleProfileClick(awayPlayer?.userId)}
              >
                {awayPlayer && match.ratingStatus === 'finished' ? (
                  <span className="rating-away">
                    {formatRanking(awayPlayer.rating)}
                  </span>
                ) : null}
                {awayPlayer?.fullName}
              </span>
            </Styled.LineUpRow>
          ))}
        </Tab>
      </Styled.Tabs>
    </Styled.Wrapper>
  );
};

export default Match;
