import styled from 'styled-components/macro';
import { SearchBar as _SearchBar } from 'shared/components/search-bar/SearchBar';
import { device } from 'shared/styles/MediaQuery';
import { Colours } from 'shared/styles/Colours';
import { Loader as _Loader } from 'shared/icons/loader';
import { Button } from 'shared/components/buttons/Button';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  padding: 0 1rem 5rem;
`;

const Wrapper = styled.div`
  width: min(900px, calc(100vw - 2rem));
  margin: auto;
  padding: 32px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  max-width: 378px;

  .logo {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  & > h6 {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;
    flex: 1 0 auto;
  }
`;

const MatchContainer = styled.div`
  background-color: ${Colours.darkBluish};
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 1rem 1.5rem;
  margin: 1rem 0 0;
  border-radius: 16px;
`;

const VoteInfo = styled.div`
  align-self: stretch;
  align-items: left;
  padding-top: 8px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
`;

const ScoreContainer = styled.div`
  align-self: stretch;
  align-items: left;
  padding-top: 8px;
  display: flex;
  flex-direction: row;
`;
const SquadName = styled.div`
  margin: 8px;
  font-size: 16px;
  font-weight: bold;
`;

const MatchDate = styled.div`
  color: ${Colours.secondaryText};
  font-size: 12px;
  font-weight: bold;
`;

const Loader = styled(_Loader)`
  margin: auto;
`;

const PlayerContainer = styled.div`
  margin: 0.5rem 0 0;
  font-size: 14px;
  font-weight: bold;
`;
const PlayerName = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
`;
const PlayingStyle = styled.p`
  color: ${Colours.secondaryText};
  margin: 0 0 0.5rem;
  font-weight: bold;
`;

const InviteContent = styled.p`
  color: ${Colours.secondaryText};
  margin: 0 0 0.5rem;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
`;

const White = styled.span`
  color: white;
`;

const Score = styled.div`
  background-color: #282c2e;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;

  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  font-weight: bold;
`;

export default {
  Page,
  Wrapper,
  SquadName,
  MatchDate,
  PlayerContainer,
  PlayerName,
  PlayingStyle,
  InviteContent,
  Loader,
  White,
  MatchContainer,
  Score,
  ScoreContainer,
  VoteInfo,
};
