export const configProd = {
  apiKey: 'AIzaSyDw4n6u5wZecij_F7DDqTAF-amhmYnr_oM',
  authDomain: 'footify-c99ce.firebaseapp.com',
  projectId: 'footify-c99ce',
  storageBucket: 'footify-c99ce.appspot.com',
  messagingSenderId: '630659402250',
  appId: '1:630659402250:web:edc43e14f56fe8db3b2df3',
  measurementId: 'G-NJEW4DC2HB',
};

export const configDev = {
  apiKey: 'AIzaSyBWN3faoYTPVPHzKbcu_itOK1GXsLYgo3k',
  authDomain: 'footify-2e17f.firebaseapp.com',
  databaseURL: 'https://footify-2e17f.firebaseio.com',
  projectId: 'footify-2e17f',
  storageBucket: 'footify-2e17f.appspot.com',
  messagingSenderId: '461796106960',
  appId: '1:461796106960:web:ddfb587949bb0c07',
};

export const firebaseConfig =
  process.env.REACT_APP_DEPLOY_TARGET === 'production' ? configProd : configDev;
