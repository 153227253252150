import React from 'react';

export const IcoBell: React.FC = (props) => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" {...props}>
    <path
      d="M1.25 6.78301C1.25 5.92173 1.53453 5.15727 2.05671 4.62971C2.75886 3.92033 3.72299 3.05385 4.80227 2.37196C5.89675 1.68047 6.99779 1.25 8 1.25C9.00222 1.25 10.1033 1.68047 11.1977 2.37196C12.277 3.05385 13.2411 3.92033 13.9433 4.62971C14.4655 5.15727 14.75 5.92173 14.75 6.78301V16.75H1.25V6.78301Z"
      stroke="#999999"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 21C9.10457 21 10 20.1046 10 19C10 17.8954 9.10457 17 8 17C6.89543 17 6 17.8954 6 19C6 20.1046 6.89543 21 8 21Z"
      stroke="#999999"
      strokeWidth="2"
    />
  </svg>
);
