import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { Loader as _Loader } from 'shared/icons/loader';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 1rem 2rem;
`;

const Wrapper = styled.div`
  width: min(900px, calc(100vw - 2rem));
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
`;

const MatchName = styled.h3`
  margin: 2rem 0 1rem;
`;

const MatchInfo = styled.h4`
  margin: 0;
  text-align: center;
  small {
    margin-top: 1rem;
    display: block;
  }
`;

const TeamName = styled.h5`
  margin-top: 3rem;
  align-self: flex-start;
`;

const Lineup = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;

    .goals {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .name {
      margin-top: 1rem;
    }

    .style {
      color: ${Colours.secondaryText};
    }
  }
`;

const Loader = styled(_Loader)`
  margin: auto;
`;

export default {
  Page,
  Wrapper,
  MatchName,
  MatchInfo,
  TeamName,
  Lineup,
  Loader,
};
