import React from 'react';
import { find, mergeDeepRight, propEq } from 'ramda';
import { Colours } from '../../styles/Colours';
import {
  PlayingPosition,
  PlayingPositionColours,
} from '../../types/PlayingPosition';
import Styled from './Position.styles';

const PositionsLayout = [
  { name: 'F', col: 3, color: Colours.primaryText },
  { name: 'S', col: 4, color: Colours.primaryText, style: { top: '-1.5rem' } },
  { name: 'LW', col: 1, color: Colours.primaryText },
  { name: 'AM', col: 3, color: Colours.primaryText },
  { name: 'RW', col: 5, color: Colours.primaryText },
  { name: 'LM', col: 1, color: Colours.primaryDark },
  { name: 'CM', col: 3, color: Colours.primaryDark },
  { name: 'RM', col: 5, color: Colours.primaryDark },
  {
    name: 'LWB',
    col: 1,
    color: Colours.primaryDark,
    style: { left: '-1.5rem' },
  },
  { name: 'DM', col: 3, color: Colours.primaryDark },
  {
    name: 'RWB',
    col: 5,
    color: Colours.primaryDark,
    style: { right: '-1.5rem' },
  },
  { name: 'LB', color: Colours.primaryDark },
  { name: 'LCB', color: Colours.primaryDark },
  { name: 'CB', color: Colours.primaryDark },
  { name: 'RCB', color: Colours.primaryDark },
  { name: 'RB', color: Colours.primaryDark },
  { name: 'SW', col: 3, color: Colours.primaryDark },
  { name: 'GK', col: 3, color: Colours.primaryText },
];

const getPositionLayout = (
  name: string
): { name: string; col: number; color?: Colours } =>
  find(propEq('name', name), PositionsLayout)!;

export interface Props {
  value?: PlayingPosition;
  onChange: (value: PlayingPosition) => void;
}

export const Position: React.FC<Props> = ({ value, onChange, ...props }) => {
  const positions = PlayingPositionColours.map((position) =>
    mergeDeepRight(getPositionLayout(position.name), position)
  );

  return (
    <Styled.PositionGrid {...props}>
      {positions.map((position: any) => (
        <Styled.PlayingPosition
          key={position.name}
          isActive={value === position.name}
          onClick={() => onChange(position.name as PlayingPosition)}
          {...position}
        />
      ))}
    </Styled.PositionGrid>
  );
};
