import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Tab } from 'shared/components/tabs/tabs';
import { CreateMatch } from 'shared/types/CreateMatch';
import { Colours } from 'shared/styles/Colours';
import { Button } from 'shared/components/buttons/Button';
import { Sidebar } from 'shared/components/sidebar/sidebar';
import { MatchEvent, MatchPeriods } from 'shared/types/MatchEvent';
import { Event } from '../match/components/Event';
import { AddEvent } from './add-event/AddEvent';
import { Header } from './Header';
import { getEventsByPeriod } from '../match/utils';
import { PenShootoutEvent } from '../match/components/PenShootoutEvent';
import StyledMatch from '../match/Match.styles';
import Styled from './AddMatch.styles';

dayjs.extend(duration);

export const MatchEvents: React.FC<{
  allPlayers: any[];
  onNext: (values: CreateMatch) => void;
}> = ({ allPlayers, onNext }) => {
  const { getValues, setValue } = useFormContext<CreateMatch>();
  const [event, setEvent] = useState<Partial<MatchEvent> | null>(null);
  const {
    homeTeamPlayers,
    awayTeamPlayers,
    homeSquadId,
    awaySquadId,
    injuryTimeByPeriod,
    score0,
    score1,
    events,
    settings,
    ...values
  } = getValues();
  const totalMatchLength = settings.length + settings.extraTime;
  const totalMatchInjuryTime = Object.values(injuryTimeByPeriod).reduce(
    (acc, it) => acc + it,
    0
  );

  const lineUps = Array(
    Math.max(homeTeamPlayers.length, awayTeamPlayers.length)
  )
    .fill(null)
    .map((_, idx) => [homeTeamPlayers[idx], awayTeamPlayers[idx]]);

  const homeTeam = homeTeamPlayers.map((id) =>
    allPlayers.find((player) => player.userId === id)
  );

  const otherTeam = awayTeamPlayers.map((id) =>
    allPlayers.find((player) => player.userId === id)
  );

  const eventsByPeriod = getEventsByPeriod(events);

  const handleAddEvent = (event: MatchEvent | null): void => {
    if (event === null) {
      // we'll pass null from penalties as a signal to close drawer
      return setEvent(null);
    }

    const [time, extraTime = 0] = event.time?.toString().split('+') ?? [0];
    event.time = (Number(time) + Number(extraTime)) * 60;
    if (
      event.type === 'goal' ||
      event.type === 'owngoal' ||
      (event.type === 'penalty' && event.penaltyResult === 'GOAL')
    ) {
      if (event.isHomeTeam) {
        setValue('score0', score0 + 1);
      } else {
        setValue('score1', score1 + 1);
      }
    }

    if (settings.haveHalfTime) {
      switch (event.matchPeriod) {
        case '1st half extra time':
          if (Number(time) > (values.time + values.extraTime) / 2) {
            event.matchPeriod = '2nd half extra time';
          }
          break;
        case '1st half':
          if (Number(time) > values.time / 2) {
            event.matchPeriod = '2nd half';
          }
          break;
      }
    }

    if (event.matchPeriod === '2nd half extra time' && extraTime) {
      event.matchPeriod = `${event.matchPeriod} injury time` as MatchPeriods;
    }

    // if user clicked again on existing penalty event, replace it
    const replaceIndex =
      event.index === undefined
        ? -1
        : events.findIndex(
            (e: MatchEvent) =>
              e.index == event.index &&
              e.isHomeTeam === event.isHomeTeam &&
              e.matchPeriod === event.matchPeriod
          );

    if (replaceIndex !== -1) {
      events.splice(replaceIndex, 1, event);
      setValue('events', events, { shouldValidate: true });
    } else {
      setValue('events', [...events, event], { shouldValidate: true });
    }

    // for pen shootout event we need to keep drawer open,
    // setting event to null will hide drawer
    // TODO: dont rely on event state
    if (!event.matchPeriod.includes('shootout')) {
      setEvent(null);
    }
  };

  return (
    <Styled.Wrapper>
      <Header
        leftButtonTitle="Cancel"
        onLeftButtonClick={() => null}
        rightButtonTitle="Save"
        onRightButtonClick={() => onNext(getValues())}
      />
      <StyledMatch.Logo />
      <h5>
        {values.title ?? ' - '}{' '}
        {settings.length ? `(${settings?.length}min)` : null}
      </h5>
      <StyledMatch.Date>{values.startAt}</StyledMatch.Date>

      <StyledMatch.Result>
        <StyledMatch.Score style={{ backgroundColor: Colours.modalBackground }}>
          {score0}
          <span>{values.homeTeamName}</span>
          <Button
            small
            style={{ marginTop: '2rem' }}
            onClick={() =>
              setEvent({
                isHomeTeam: true,
                teamName: values.homeTeamName!,
              })
            }
          >
            Add incident
          </Button>
        </StyledMatch.Score>
        <Styled.Duration>
          {[90, 120].includes(totalMatchLength)
            ? 'FT'
            : totalMatchInjuryTime
            ? `${totalMatchLength}:00+${totalMatchInjuryTime}`
            : `${totalMatchLength}:00`}
        </Styled.Duration>
        <StyledMatch.Score style={{ backgroundColor: Colours.modalBackground }}>
          {score1}
          <span>{values.awayTeamName}</span>
          <Button
            small
            style={{ marginTop: '2rem' }}
            onClick={() =>
              setEvent({
                isHomeTeam: false,
                teamName: values.awayTeamName!,
              })
            }
          >
            Add incident
          </Button>
        </StyledMatch.Score>
      </StyledMatch.Result>
      <StyledMatch.Tabs>
        <Tab header={<span>Match Report</span>}>
          {Object.entries(eventsByPeriod).map(([period, periodEvents]) =>
            periodEvents!.length === 0 ? null : (
              <React.Fragment key={period}>
                {settings.haveHalfTime && <h5>{period}</h5>}
                {periodEvents!.map((pev, idx) =>
                  period.includes('shootout') ? (
                    <PenShootoutEvent
                      key={idx}
                      eventHome={pev[0]}
                      eventAway={pev[1]}
                    />
                  ) : (
                    <Event key={idx} event={pev} />
                  )
                )}
              </React.Fragment>
            )
          )}
        </Tab>
        <Tab header={<span>Line-ups</span>}>
          {lineUps.map(([homePlayer, awayPlayer]) => (
            <StyledMatch.LineUpRow key={homePlayer}>
              <span className="playerHome">
                {
                  allPlayers.find((player) => player.userId === homePlayer)
                    ?.fullName
                }
              </span>
              <span> - &nbsp; - </span>
              <span className="playerAway">
                {
                  allPlayers.find((player) => player.userId === awayPlayer)
                    ?.fullName
                }
              </span>
            </StyledMatch.LineUpRow>
          ))}
        </Tab>
      </StyledMatch.Tabs>
      <Sidebar width="400px" showsClose={false}>
        {event === null ? null : (
          <AddEvent
            event={event}
            oppositeSquadName={values.awayTeamName!}
            team={homeTeam}
            oppositeTeam={otherTeam}
            onCancel={() => setEvent(null)}
            onDone={handleAddEvent}
          />
        )}
      </Sidebar>
    </Styled.Wrapper>
  );
};
