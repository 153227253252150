import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';

const Wrapper = styled.div`
  background: url('/images/bg_profile_card.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  overflow-y: auto;
  padding: 2rem;
  width: 100%;
`;

const Card = styled.div`
  align-items: center;
  background-color: ${Colours.darkGrey};
  display: flex;
  flex: 0;
  flex-direction: column;
  margin: 2rem auto;
  min-height: 560px;
  padding: 3rem 2rem;
  width: 320px;
`;

const PlayerScore = styled.div`
  align-items: center;
  background: url('/images/bg_player_score.svg');
  color: ${Colours.darkGrey};
  display: flex;
  height: 72px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 38px;
  justify-content: center;
  line-height: 1rem;
  margin: 1rem 0;
  width: 64px;
`;

const RatingGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    font-weight: 600;
    margin: auto;
  }
`;

export default { Wrapper, Card, PlayerScore, RatingGrid, Footer };
