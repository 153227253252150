import { getType, Reducer } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { mergeRight } from 'ramda';
import { UserProfile } from 'shared/types/UserProfile';
import actions from './profileActions';
import uiActions from 'store/uiActions';

export interface ProfileState {
  userProfiles: Record<string, UserProfile>;
  selectedProfileId: string | null;
  isFetching: boolean;
  isSaving: boolean;
  error: string | null;
}

export const initialProfileState: ProfileState = {
  userProfiles: {},
  selectedProfileId: null,
  isFetching: false,
  isSaving: false,
  error: null,
};

const profileReducer: Reducer<ProfileState, AnyAction> = (
  state = initialProfileState,
  action: AnyAction
) => {
  const { type, payload } = action;
  const updateState = mergeRight(state);

  switch (type) {
    case getType(actions.selectProfile): {
      return updateState<Partial<ProfileState>>({
        selectedProfileId: payload,
      });
    }
    case getType(uiActions.userAuthStateChanged): {
      if (state.selectedProfileId) {
        return state;
      }

      return updateState<Partial<ProfileState>>({
        selectedProfileId: payload?.uid,
      });
    }

    case getType(actions.loadProfile.request): {
      return updateState<Partial<ProfileState>>({
        isFetching: true,
        error: null,
      });
    }

    case getType(actions.loadProfile.success): {
      return updateState<Partial<ProfileState>>({
        isFetching: false,
        userProfiles: { ...state.userProfiles, [payload.userId]: payload },
      });
    }

    case getType(actions.loadProfile.failure): {
      return updateState<Partial<ProfileState>>({
        isFetching: false,
        error: payload,
      });
    }

    case getType(actions.saveProfile.request): {
      return updateState<Partial<ProfileState>>({
        isSaving: true,
        error: null,
      });
    }

    case getType(actions.saveProfile.success): {
      return updateState<Partial<ProfileState>>({
        isSaving: false,
        userProfiles: {
          ...state.userProfiles,
          [payload.userId]: {
            ...state.userProfiles[payload.userId],
            ...payload,
          },
        },
      });
    }

    case getType(actions.saveProfile.failure): {
      return updateState<Partial<ProfileState>>({
        isFetching: false,
        error: payload,
      });
    }
  }

  return state;
};

export default profileReducer;
