import firebase from 'firebase/app';

const checkEmailExists = async (email: string): Promise<boolean> => {
  const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
  return methods.length > 0;
};

const login = (email: string, password: string): Promise<auth.UserCredential> =>
  firebase.auth().signInWithEmailAndPassword(email, password);

const requestPasswordReset = (email: string): Promise<void> => {
  return firebase.auth().sendPasswordResetEmail(email);
};

export default {
  checkEmailExists,
  login,
  requestPasswordReset,
};
