import React from 'react';
import styled from 'styled-components/macro';
import Styled from '../AddMatch.styles';
import { MotionWrapper } from './AddEvent';
import { Header } from '../Header';

const OptionLabel = styled.div<{ size?: number }>`
  font-size: ${({ size }) => size ?? 2}rem;
  text-align: center;
  font-weight: bold;
`;

export const ChooseOption: React.FC<{
  onSelect: (type: string) => void;
  options?: string[];
  onBack: () => void;
  title: string;
}> = ({ title, onSelect, onBack, options = ['GOAL', 'Penalty'] }) => (
  <MotionWrapper>
    <Header onLeftButtonClick={onBack} title={title} rightButtonTitle={null} />
    <ul>
      {options?.map((option) => (
        <Styled.ListOption key={option} onClick={() => onSelect(option)}>
          <OptionLabel size={option === 'GOAL' ? 4 : 2}>{option}</OptionLabel>
        </Styled.ListOption>
      ))}
    </ul>
  </MotionWrapper>
);
