import { all, fork, put, takeLatest, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import profileApi from '../api';
import actions from './profileActions';
import { toast } from 'react-hot-toast';

function* loadProfile(action: AnyAction): Generator {
  try {
    const profile: any = yield profileApi
      .fetchProfile(action.payload)
      .then((response) => response.data);
    yield put(actions.loadProfile.success(profile));
  } catch (e) {
    yield put(actions.loadProfile.failure(e));
  }
}

function* saveProfile(action: AnyAction): Generator {
  try {
    const uid = yield select((state) => {
      const authUid = state.ui.user.uid;
      const profile: any = Object.values(state.profile.userProfiles).find(
        (profile: any) =>
          profile.author_uid == authUid || profile.userId == authUid
      );
      return profile.userId as string;
    });
    const profile: any = yield profileApi
      .saveProfile(uid, action.payload)
      .then((response) => response.data);
    toast.success('Profile saved.');
    yield put(actions.saveProfile.success(profile));
  } catch (e) {
    yield put(actions.saveProfile.failure(e));
  }
}

function* followUser(action: AnyAction): Generator {
  try {
    yield profileApi.followUser(action.payload);
    const profile: any = yield profileApi
      .fetchProfile(action.payload)
      .then((response) => response.data);
    yield put(actions.loadProfile.success(profile));
  } catch (e) {
    yield put(actions.followUser.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(actions.loadProfile.request), loadProfile);
  yield takeLatest(getType(actions.saveProfile.request), saveProfile);
  yield takeLatest(getType(actions.followUser.request), followUser);
}

export function* profileSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
