import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RouteComponentProps } from '@reach/router';
import Rating from 'react-rating';
import { Avatar } from 'shared/components/Avatar';
import { IcoCircleEmpty, IcoCircleFull } from 'shared/icons/circle';
import { Button } from 'shared/components/buttons/Button';
import { useInterval } from 'shared/hooks/useInterval';
import Styled from './RatePlayers.styles';
import { toast } from 'react-hot-toast';

export const RatePlayers: React.FC<RouteComponentProps<{ matchId: string }>> =
  ({ matchId, ...props }) => {
    const localRating = localStorage.getItem(`match-${matchId}`);
    const [match, setMatch] = useState<any>(null);
    const [ratings, setRatings] = useState<Record<string, number>>(
      localRating ? JSON.parse(localRating) : {}
    );
    const [isReadOnly, setIsReadOnly] = useState(localRating ? true : false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
      axios
        .get(`/api/match/${matchId}/rating`)
        .then((response) => setMatch(response.data))
        .catch((e) => toast.error(e.response.data.error));
    }, []);

    useInterval(() => {
      if (match) {
        const remainingTime =
          3600 -
          (Math.ceil(Date.now() / 1000) -
            (match.startAt._seconds + match.time));
        setTimer(Math.max(0, remainingTime));
      }
    }, 1000);

    const submitRating = (event: React.SyntheticEvent): void => {
      const allPlayers = [...match.homeTeamPlayers, ...match.awayTeamPlayers];
      if (Object.keys(ratings).length < allPlayers?.length) {
        toast('Please rate all players');
      }

      localStorage.setItem(`match-${matchId}`, JSON.stringify(ratings));

      axios
        .post(`/api/match/${matchId}/rating`, { ratings })
        .then(() => setIsReadOnly(true))
        .catch((e) =>
          toast.error('Error submitting your rating. Please try again later.')
        );
    };

    return match ? (
      <Styled.Page {...props}>
        <Styled.Wrapper>
          <Styled.MatchName>{match.title}</Styled.MatchName>
          <Styled.MatchInfo>
            Between <strong>{match.homeTeamName}</strong> and{' '}
            <strong>{match.awayTeamName}</strong>
            <small>
              Player rating open for {Math.floor(timer / 60)}:{timer % 60}
            </small>
          </Styled.MatchInfo>

          <Styled.TeamName>{match.homeTeamName} team lineup</Styled.TeamName>
          <Styled.Lineup>
            {match!.homeTeamPlayers.map((player: any) => (
              <li key={player.userId}>
                <Avatar
                  src={player.avatar || '/images/user-placeholder.svg'}
                  position={player.position}
                />{' '}
                {player.fullName}
                <Rating
                  readonly={isReadOnly}
                  emptySymbol={<IcoCircleEmpty value={0} />}
                  fullSymbol={<IcoCircleFull value={0} />}
                  initialRating={ratings[player.userId] ?? 0}
                  onChange={(value: number) =>
                    setRatings({ ...ratings, [player.userId]: value })
                  }
                />
              </li>
            ))}
          </Styled.Lineup>
          <br />
          <br />

          <Styled.TeamName>{match.awayTeamName} team lineup</Styled.TeamName>
          <Styled.Lineup>
            {match!.awayTeamPlayers?.map((player: any) => (
              <li key={player.userId}>
                <Avatar
                  src={player.avatar || '/images/user-placeholder.svg'}
                  position={player.position}
                />{' '}
                {player.fullName}
                <Rating
                  readonly={isReadOnly}
                  emptySymbol={<IcoCircleEmpty value={0} />}
                  fullSymbol={<IcoCircleFull value={0} />}
                  initialRating={ratings[player.userId] ?? 0}
                  onChange={(value: number) =>
                    setRatings({ ...ratings, [player.userId]: value })
                  }
                />
              </li>
            ))}
          </Styled.Lineup>

          {!isReadOnly ? (
            <Button onClick={submitRating}>Submit rating</Button>
          ) : null}
        </Styled.Wrapper>
      </Styled.Page>
    ) : (
      <Styled.Page {...props}>
        <Styled.Loader />
      </Styled.Page>
    );
  };
