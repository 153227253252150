import axios, { AxiosResponse } from 'axios';
import { UserProfile } from 'shared/types/UserProfile';

export const fetchProfile = (
  uid: string
): Promise<AxiosResponse<UserProfile>> => {
  return axios.get<UserProfile>(`/api/users/${uid}`);
};

export const saveProfile = (
  uid: string,
  profileData: Partial<UserProfile>
): Promise<AxiosResponse<UserProfile>> =>
  axios.patch(`/api/users/${uid}`, profileData);

export const followUser = (uid: string): Promise<AxiosResponse<UserProfile>> =>
  axios.patch(`/api/users/${uid}/follow`);

export const fetchUserNotifications = (): Promise<AxiosResponse> =>
  axios.get(`/api/notifications`);

export const markAsRead = (
  type: 'general' | 'received'
): Promise<AxiosResponse> => axios.get(`/api/notifications/markAsRead/${type}`);

export default {
  fetchProfile,
  saveProfile,
  followUser,
  fetchUserNotifications,
  markAsRead,
};
