import React from 'react';
import app from 'firebase/app';

export interface AuthContextProps {
  emailVerified: boolean;
  token: string | null;
  user: app.User | null;
  isInitialising: boolean;
  firebase: app.app.App | null;
  logout: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  emailVerified: false,
  token: null,
  user: null,
  isInitialising: true,
  firebase: null,
  logout: () => null,
});
