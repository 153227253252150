import styled from 'styled-components/macro';
import { IcoUserEdit as _Icon } from 'shared/icons/avatar-edit';

const EditIcon = styled(_Icon)`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: none;
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  padding: 1rem;
  cursor: pointer;

  &:hover ${EditIcon} {
    display: inline-block;
  }

  input[type='file'] {
    display: none;
  }
`;

export default {
  Wrapper,
  EditIcon,
};
