import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { device } from 'shared/styles/MediaQuery';
import { Button } from 'shared/components/buttons/Button';

const Page = styled.div`
  background-color: ${Colours.modalBackground};
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 2rem 1rem;
  width: min(600px, 100vw);

  ${device.tablet} {
    height: 80vh;
    padding: 2rem 3rem;
  }

  h4 {
    text-align: center;
  }
`;

const SearchInput = styled.div`
  background-color: ${Colours.darkGrey};
  color: ${Colours.primaryText};
  display: flex;
  border-radius: 10px;
  padding: 11px;

  input {
    background-color: transparent;
    border: none;
    color: currentColor;
    margin-left: 1rem;
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${Colours.primaryText};
    }
  }
`;

const LocationsList = styled.ul`
  height: 100%;
  margin-top: 1rem;
  overflow: auto;
  padding: 0 3rem 0 0;

  li {
    border-bottom: 1px solid ${Colours.primaryDark};
    color: ${Colours.secondaryText};
    cursor: pointer;
    padding: 1.6rem 0;
  }
`;

const CancelButton = styled(Button)`
  align-self: center;
  margin-top: 3rem;
`;

export default {
  Page,
  LocationsList,
  SearchInput,
  CancelButton,
};
