import React from 'react';

export const IcoBack: React.FC = (props) => (
  <svg width={10} height={16} viewBox="0 0 10 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.45.347a1.297 1.297 0 011.773.007c.489.468.493 1.227.008 1.7L3.04 7.998l6.191 5.94c.236.227.37.534.37.854 0 .321-.134.629-.37.855a1.298 1.298 0 01-1.781 0L.369 8.85A1.184 1.184 0 010 7.996c0-.32.133-.628.369-.855L7.449.347z"
      fill="#fff"
    />
  </svg>
);
