import React from 'react';
import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';
import { PlayingPosition, propsForPosition } from '../types/PlayingPosition';

const Position = styled.div<{
  bgColour: string;
  name: string;
  color?: string;
  fontSize?: '1rem';
}>`
  background-color: ${(props) => props.bgColour};
  border-radius: 50%;
  color: ${(props) => props.color ?? Colours.tertiaryText};
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};
  height: 3em;
  line-height: 3em;
  overflow: hidden;
  text-align: center;
  width: 3em;
`;

export interface Props {
  position: PlayingPosition;
  fontSize?: string;
}

export const PositionBadge: React.FC<Props> = ({ position, ...props }) => {
  const positionProps: any = position && propsForPosition(position);

  return (
    <Position {...positionProps} {...props}>
      {position}
    </Position>
  );
};
