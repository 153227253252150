import styled, { css } from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

export interface Props {
  color?: string | null;
  textColor?: string;
  secondary?: boolean;
  width?: string;
  small?: boolean;
}

export const Button = styled.button<Props>`
  align-items: center;
  background-color: ${({ color, secondary }) =>
    color ? color : secondary ? Colours.darkGrey : Colours.primaryText};
  border-color: transparent;
  border-radius: 2px;
  color: ${({ textColor, secondary }) =>
    textColor ? textColor : secondary ? Colours.primaryText : Colours.darkGrey};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: space-around;
  letter-spacing: -0.24px;
  line-height: 1rem;
  margin-bottom: 1rem;
  min-height: 48px;
  outline: 0;
  transition: opacity 100ms ease-in;
  padding: 8px 24px;
  width: ${({ width }) => (width ? width : '160px')};

  ${({ small }) =>
    small &&
    css`
      min-height: auto;
      max-height: 40px;
    `}

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.65;
  }

  &:disabled {
    opacity: 0.3;
  }
`;
