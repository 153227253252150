import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { Button } from 'shared/components/buttons/Button';
import { Colours } from 'shared/styles/Colours';
import { RadioInput } from 'shared/components/input/RadioInput';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import Styled from './AddMatch.styles';

interface Props {
  onNext: () => void;
  onCancel: () => void;
}

export const MatchMode: React.FC<Props> = ({ onNext, onCancel }) => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Styled.Wrapper
      initial={{ opacity: 0, transform: 'translateX(100%)' }}
      animate={{ opacity: 1, transform: 'translateX(0px)' }}
      exit={{ opacity: 0, transform: 'translateX(-100%)' }}
    >
      <Styled.NavBar>
        <Button
          color={Colours.modalBackground}
          textColor={Colours.primaryText}
          width="auto"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <div className="title" />
        <Button
          type="button"
          color={Colours.accent}
          textColor={Colours.primaryText}
          width="auto"
          onClick={() =>
            trigger('matchMode').then((isValid) => {
              isValid && onNext();
            })
          }
        >
          Next
        </Button>
      </Styled.NavBar>

      <main>
        <RadioInput
          label="Vs match"
          subtitle="Matches against other squads"
          value="vs"
          register={register('matchMode', { required: true })}
        />
        <RadioInput
          label="Squad mode"
          subtitle="Play a kickabout between squad members"
          value="squadMode"
          register={register('matchMode', { required: true })}
        />
        <ValidationError message={(errors.mode as FieldError)?.message} />
      </main>
    </Styled.Wrapper>
  );
};
