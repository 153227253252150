import React from 'react';

export const IcoPenMissed: React.FC = (props) => (
  <svg viewBox="0 0 28 24" fill="none" {...props}>
    <rect x="12" y="1" width="2" height="16" rx="1" fill="white" />
    <rect width="14" height="2" rx="1" fill="white" />
    <rect
      x="19.8789"
      y="5.46448"
      width="8"
      height="2"
      rx="1"
      transform="rotate(45 19.8789 5.46448)"
      fill="white"
    />
    <rect
      x="25.5352"
      y="6.87866"
      width="8"
      height="2"
      rx="1"
      transform="rotate(135 25.5352 6.87866)"
      fill="white"
    />
  </svg>
);
