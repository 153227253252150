import React from 'react';
import { PositionBadge } from 'shared/components/PositionBadge';
import { UserProfile } from 'shared/types/UserProfile';
import { Avatar } from 'shared/components/Avatar';
import { Header } from '../Header';
import Styled from '../AddMatch.styles';
import { MotionWrapper } from './AddEvent';

export const PlayerSelect: React.FC<{
  title: string;
  team: UserProfile[];
  showOwnGoal?: boolean;
  onBack: () => void;
  onSkip: () => void;
  onPlayerSelect: (player: UserProfile | 'owngoal' | null) => void;
}> = ({
  title = 'Scored by',
  team,
  showOwnGoal = false,
  onBack,
  onSkip,
  onPlayerSelect,
}) => {
  return (
    <MotionWrapper>
      <Header
        title={title}
        onLeftButtonClick={onBack}
        rightButtonTitle="Skip"
        rightButtonColour="white"
        onRightButtonClick={onSkip}
      />

      <h4>{title}</h4>
      <Styled.EventPlayersList>
        {showOwnGoal && (
          <li onClick={() => onPlayerSelect('owngoal')}>
            <span /> <span className="og">OG</span>
            Own goal
          </li>
        )}
        {team?.length > 0 ? (
          team.map((player) => (
            <li key={player.userId} onClick={() => onPlayerSelect(player)}>
              <PositionBadge position={player.position} fontSize="8px" />
              <Avatar
                src={
                  player.profileImagePathThumbnail ?? player.profileImagePath
                }
              />
              {player.fullName}
            </li>
          ))
        ) : (
          <li onClick={() => onPlayerSelect(null)}>
            <span />
            <Avatar />
            Mystery Player
          </li>
        )}
      </Styled.EventPlayersList>
    </MotionWrapper>
  );
};
