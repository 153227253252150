import React from 'react';
import { Loader } from 'shared/icons/loader';
import { PositionBadge } from 'shared/components/PositionBadge';
import { Avatar } from 'shared/components/Avatar';
import Styled from './AddMatch.styles';
import { Header } from './Header';

export const SelectPlayers: React.FC<{
  title: string;
  noPlayers: number;
  noSubstitutes: number;
  players: any[] | null;
  selectedPlayers: string[];
  selectedSubs: string[];
  onPlayerSelect: (player: string, type: 'Sub' | 'Start') => void;
  onDone: () => void;
}> = ({
  noSubstitutes,
  noPlayers,
  title,
  players,
  selectedPlayers,
  selectedSubs,
  onPlayerSelect,
  onDone,
}) => {
  const handlePLayerSelect = (
    e: React.SyntheticEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.currentTarget;
    onPlayerSelect(name, value as 'Sub' | 'Start');
  };

  return !players ? (
    <div style={{ marginTop: '2rem', textAlign: 'center' }}>
      <div>Loading team members</div>
      <Loader />
    </div>
  ) : (
    <Styled.SelectPlayersWrapper>
      <Header
        title={title}
        leftButtonTitle={null}
        rightButtonTitle="Done"
        rightButtonColour="white"
        onRightButtonClick={onDone}
        border={true}
      />
      <div>
        <h4>{players?.length ?? ' - '} Squad players</h4>
        <strong>
          {selectedPlayers.length}/{noPlayers}
        </strong>{' '}
        Starting line-up players and{' '}
        <strong>
          {selectedSubs.length}/{noSubstitutes}
        </strong>{' '}
        Substitutes added
      </div>
      <ul style={{ marginTop: '2rem', overflow: 'auto' }}>
        {players?.map((player) => (
          <Styled.PlayerSelect key={player.userId}>
            <PositionBadge position={player.position} fontSize="0.5rem" />
            <Avatar
              src={player.profileImagePathThumbnail ?? '/images/avatar.svg'}
              size={60}
            />

            {player.fullName}
            <input
              type="radio"
              name={player.userId}
              value="Sub"
              checked={selectedSubs.includes(player.userId)}
              onClick={handlePLayerSelect}
              readOnly
            />
            <input
              type="radio"
              name={player.userId}
              value="Start"
              checked={selectedPlayers.includes(player.userId)}
              onClick={handlePLayerSelect}
              readOnly
            />
          </Styled.PlayerSelect>
        ))}
      </ul>
    </Styled.SelectPlayersWrapper>
  );
};
