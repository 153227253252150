import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';
import React from 'react';

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 4rem;
  border-bottom: 1px solid ${Colours.divider};
`;

const Title = styled.div`
  font-size: 0.9rem;
  line-height: 1.25em;
  text-align: center;
  width: 70%;
`;

export interface Props {
  counter: number;
  title: string;
}

export const StatsBadge: React.FC<Props> = ({ counter, title, ...props }) => (
  <Wrapper {...props}>
    {counter}
    <Title>{title}</Title>
  </Wrapper>
);
