import React, { useEffect, useRef, useState } from 'react';
import { IcoSearch } from 'shared/icons/search';
import Styled from './Location.styles';
import { Locations } from './Locations.list';

interface Props {
  onLocationSelected: (location: string) => void;
  onCancel: () => void;
}

export const Location: React.FC<Props> = ({
  onLocationSelected,
  onCancel,
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [locations, setLocations] = useState(Locations);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setLocations(
      query === ''
        ? Locations
        : Locations.filter((location) =>
            location.toLowerCase().includes(query.toLowerCase())
          )
    );
  }, [query]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Styled.Page {...props}>
      <h4>Where are you based?</h4>
      <Styled.SearchInput>
        <IcoSearch />
        <input
          ref={inputRef}
          placeholder="Search for your location"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Styled.SearchInput>
      <Styled.LocationsList>
        {locations.map((location) => (
          <li key={location} onClick={() => onLocationSelected(location)}>
            {location}
          </li>
        ))}
      </Styled.LocationsList>
      <Styled.CancelButton onClick={onCancel}>Cancel</Styled.CancelButton>
    </Styled.Page>
  );
};
