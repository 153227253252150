import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const ModalPortal: React.FC<{ anchorElement?: HTMLElement }> = ({
  anchorElement,
  children,
}) => {
  const [el] = useState(document.createElement('div'));

  useEffect(() => {
    (anchorElement || document.body).appendChild(el);
    document.body.style.overflow = 'hidden';

    return (): void => {
      document.body.style.overflow = '';
      (anchorElement || document.body).removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(children, el);
};
