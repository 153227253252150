import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { AnyAction } from 'redux';
import firebase from 'firebase/app';
import actions from './loginActions';
import api from '../api';

function* login({ payload }: AnyAction): Generator {
  try {
    const response = yield api.login(payload.username, payload.password);
    yield put(actions.login.success(response as any));
  } catch (e) {
    yield put(actions.login.failure(e));
  }
}

function* resetPassword({ payload }: AnyAction): Generator {
  try {
    yield firebase.auth().sendPasswordResetEmail(payload);
    yield put(actions.resetPassword.success());
  } catch (e) {
    yield put(actions.resetPassword.failure(e));
  }
}

function* watchAPIRequests(): Generator {
  yield takeLatest(getType(actions.login.request), login);
  yield takeLatest(getType(actions.resetPassword.request), resetPassword);
}

export function* loginSaga(): Generator {
  yield all([fork(watchAPIRequests)]);
}
