import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Input } from 'shared/components/input/Input';
import { Button } from 'shared/components/buttons/Button';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Loader } from 'shared/icons/loader';
import { AppState } from 'store/rootReducer';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import actions from './store/loginActions';
import { LoginState } from './store/loginReducer';
import Styled from './Login.styles';

const validationSchema = Yup.object({
  username: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  password: Yup.string().required('Please enter a password'),
});

const Login: React.FC<RouteComponentProps> = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { isFetching, loginError, passwordResetError, passwordResetStatus } =
    useSelector<AppState, LoginState>((state) => state.login);

  useEffect(() => {
    if (modalOpen && passwordResetStatus === 'success') {
      setModalOpen(false);
      alert("We've sent instructions to your email address");
    }
  }, [modalOpen, passwordResetStatus]);

  const onSubmit = ({ username, password }: any): void => {
    dispatch(actions.login.request({ username, password }));
  };

  const handlePasswordReset = (email: string): void => {
    dispatch(actions.resetPassword.request(email));
  };

  const Header: React.FC = () => (
    <></>
    // <>
    //   <h1>Log in to Footify</h1>
    //   <Styled.Hint>
    //     Don&apos;t have an account?
    //     <Link to="/register">Register here</Link>
    //   </Styled.Hint>
    // </>
  );

  return (
    <Styled.Page>
      <Styled.CoverImage>
        <Header />
      </Styled.CoverImage>

      <Styled.ScrollPane>
        <Styled.Center>
          <img className="logo" src="/images/logo_emblem.svg" width={96} />
        </Styled.Center>
        {/* <form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Logo src="/images/logo.svg" alt="Footify" />
          <Header />

          <Input label="Email:" {...register('username')} />
          <ValidationError
            message={(errors?.username as FieldError)?.message}
          />

          <Input label="Password:" type="password" {...register('password')} />
          <ValidationError
            message={(errors?.password as FieldError)?.message}
          />

          <Button type="submit" disabled={isFetching}>
            Login {isFetching && <Loader style={{ marginLeft: '1rem' }} />}
          </Button>

          <ValidationError message={loginError?.message} />

          <Styled.HintSmall>
            Forgotten password?{' '}
            <a href="#" onClick={() => setModalOpen(true)}>
              Help me please
            </a>
          </Styled.HintSmall>
        </form> */}

        <Styled.Footer>
          &copy; {new Date().getFullYear()} Footify Limited. All rights reserved
        </Styled.Footer>
      </Styled.ScrollPane>

      <ResetPasswordModal
        isOpen={modalOpen}
        onSubmit={handlePasswordReset}
        onClose={() => {
          dispatch(actions.clearResetPasswordState());
          setModalOpen(false);
        }}
        message={passwordResetError?.message}
      />
    </Styled.Page>
  );
};

export default Login;
