import { combineReducers } from 'redux';
import profileReducer, {
  ProfileState,
} from '../modules/profile/store/profileReducer';
import matchReducer, { MatchState } from '../modules/match/store/matchReducer';
import squadReducer, {
  SquadState,
} from '../modules/squad-profile/store/squadReducer';
import loginReducer, { LoginState } from '../modules/log-in/store/loginReducer';
import registerReducer, {
  RegisterState,
} from '../modules/register/store/registerReducer';
import uiReducer, { UIState } from './uiReducer';

export interface AppState {
  ui: UIState;
  profile: ProfileState;
  match: MatchState;
  squad: SquadState;
  login: LoginState;
  register: RegisterState;
}

export const rootReducer = combineReducers<AppState>({
  ui: uiReducer,
  profile: profileReducer,
  match: matchReducer,
  squad: squadReducer,
  login: loginReducer,
  register: registerReducer,
});
