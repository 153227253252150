import { createAction, createAsyncAction } from 'typesafe-actions';
import { SidebarState } from './uiReducer';

const sidebarChange = createAction('appUI/TOGGLE_MENU')<SidebarState>();

const userAuthStateChanged = createAction(
  'appUI/USER_AUTH_STATE_CHANGE',
  (payload) => {
    return payload
      ? {
          email: payload.email,
          emailVerified: payload.emailVerified,
          uid: payload.uid,
          isAnonymous: payload.isAnonymous,
        }
      : null;
  }
)<any>();

export const loadNotifications = createAsyncAction(
  'notifications/LOAD',
  'notifications/LOAD_SUCCESS',
  'notifications/LOAD_FAILURE'
)<string, any, Error>();

export const markNotificationsRead = createAsyncAction(
  'notifications/GENERAL_READ',
  'notifications/GENERAL_READ_SUCCESS',
  'notifications/GENERAL_READ_FAILURE'
)<'general' | 'received', void, Error>();

export default {
  sidebarChange,
  userAuthStateChanged,
  loadNotifications,
  markNotificationsRead,
};
