import React from 'react';

export const IcoSearch: React.FC<JSX.IntrinsicElements['svg']> = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M24.243 10.1c3.905 3.906 3.905 10.237 0 14.143-3.906 3.905-10.237 3.905-14.143 0-3.905-3.906-3.905-10.237 0-14.142 3.906-3.906 10.237-3.906 14.143 0z"
      stroke="currentColor"
      strokeWidth={4}
    />
    <rect
      x={23.535}
      y={24.95}
      width={3}
      height={10}
      rx={1.5}
      transform="rotate(-45 23.535 24.95)"
      fill="currentColor"
    />
  </svg>
);
