export const Locations = [
  'Aberdeen',
  'Aberdeenshire',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'AndorrA',
  'Angola',
  'Anguilla',
  'Angus',
  'Antarctica',
  'Antigua and Barbuda',
  'Antrim',
  'Argentina',
  'Argyll',
  'Armagh',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Avon',
  'Ayrshire',
  'Azerbaijan',
  'Åland Islands',
  'Bahamas',
  'Bahrain',
  'Banffshire',
  'Bangladesh',
  'Bangor',
  'Barbados',
  'Bath',
  'Bedfordshire',
  'Belarus',
  'Belfast',
  'Belgium',
  'Belize',
  'Benin',
  'Berkshire',
  'Bermuda',
  'Berwickshire',
  'Bhutan',
  'Birmingham',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Bradford',
  'Brazil',
  'Brighton & Hove',
  'Bristol',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Buckinghamshire',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Bute',
  'Caithness',
  'Cambodia',
  'Cambridge',
  'Cambridgeshire',
  'Cameroon',
  'Canada',
  'Canterbury',
  'Cape Verde',
  'Cardiff',
  'Carlisle',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chelmsford',
  'Cheshire',
  'Chester',
  'Chichester',
  'Chile',
  'China',
  'Christmas Island',
  'Clackmannanshire',
  'Cleveland',
  'Clwyd',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, The Democratic Republic of the',
  'Cook Islands',
  'Cornwall',
  'Costa Rica',
  "Cote D'Ivoire",
  'Coventry',
  'Croatia',
  'Cuba',
  'Cumbria',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Derby',
  'Derbyshire',
  'Devon',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Dorset',
  'Down',
  'Dumfriesshire',
  'Dunbartonshire',
  'Dundee',
  'Durham',
  'Dyfed',
  'East Lothian',
  'East Sussex',
  'Ecuador',
  'Edinburgh',
  'Egypt',
  'El Salvador',
  'Ely',
  'Equatorial Guinea',
  'Eritrea',
  'Essex',
  'Estonia',
  'Ethiopia',
  'Exeter',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fermanagh',
  'Fife',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Glasgow',
  'Gloucester',
  'Gloucestershire',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Gwent',
  'Gwynedd',
  'Haiti',
  'Hampshire',
  'Heard Island and Mcdonald Islands',
  'Hereford',
  'Herefordshire',
  'Hertfordshire',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Inverness',
  'Inverness-shire',
  'Iran, Islamic Republic Of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Isle of Wight',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kent',
  'Kenya',
  'Kincardineshire',
  'Kingston upon Hull',
  'Kinross-shire',
  'Kiribati',
  'Kirkcudbrightshire',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  'Lanarkshire',
  'Lancashire',
  'Lancaster',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Leeds',
  'Leicester',
  'Leicestershire',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Lichfield',
  'Liechtenstein',
  'Lincoln',
  'Lincolnshire',
  'Lisburn',
  'Lithuania',
  'Liverpool',
  'London',
  'London (North)',
  'London (East)',
  'London (South)',
  'London (West)',
  'Londonderry',
  'Luxembourg',
  'Macao',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Manchester',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Merseyside',
  'Mexico',
  'Micronesia, Federated States of',
  'Mid Glamorgan',
  'Midlothian',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Moray',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Nairnshire',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Newcastle upon Tyne',
  'Newport',
  'Newry',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk',
  'Norfolk Island',
  'North Yorkshire',
  'Northamptonshire',
  'Northern Mariana Islands',
  'Northumberland',
  'Norway',
  'Norwich',
  'Nottingham',
  'Nottinghamshire',
  'Oman',
  'Orkney',
  'Oxford',
  'Oxfordshire',
  'Pakistan',
  'Palau',
  'Palestinian Territory, Occupied',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peeblesshire',
  'Perth',
  'Perthshire',
  'Peru',
  'Peterborough',
  'Philippines',
  'Pitcairn',
  'Plymouth',
  'Poland',
  'Portsmouth',
  'Portugal',
  'Powys',
  'Preston',
  'Puerto Rico',
  'Qatar',
  'RWANDA',
  'Renfrewshire',
  'Reunion',
  'Ripon',
  'Romania',
  'Ross-shire',
  'Roxburghshire',
  'Russian Federation',
  'Rutland',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Salford',
  'Salisbury',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Selkirkshire',
  'Senegal',
  'Serbia and Montenegro',
  'Seychelles',
  'Sheffield',
  'Shetland',
  'Shropshire',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'Somerset',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Glamorgan',
  'South Yorkshire',
  'Southampton',
  'Spain',
  'Sri Lanka',
  'St Albans',
  'St. Asaph',
  "St. David's",
  'Staffordshire',
  'Stirling',
  'Stirlingshire',
  'Stoke-on-Trent',
  'Sudan',
  'Suffolk',
  'Sunderland',
  'Suriname',
  'Surrey',
  'Sutherland',
  'Svalbard and Jan Mayen',
  'Swansea',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Truro',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Tyne and Wear',
  'Tyrone',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wakefield',
  'Wallis and Futuna',
  'Warwickshire',
  'Wells',
  'West Glamorgan',
  'West Lothian',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Western Sahara',
  'Westminster',
  'Wigtownshire',
  'Wiltshire',
  'Winchester',
  'Wolverhampton',
  'Worcester',
  'Worcestershire',
  'Yemen',
  'York',
  'Zambia',
  'Zimbabwe',
];
