import React from 'react';
import Styled from '../AddMatch.styles';
import { MotionWrapper } from './AddEvent';
import { Header } from '../Header';

export const ChoosePeriod: React.FC<{
  title: string;
  onSelect: (time: string) => void;
  onCancel: () => void;
}> = ({ title, onSelect, onCancel }) => (
  <MotionWrapper>
    <Header
      onLeftButtonClick={onCancel}
      title={title}
      rightButtonTitle={null}
    />
    <ul>
      <Styled.ListOption onClick={() => onSelect('1st half')}>
        Normal time
      </Styled.ListOption>
      <Styled.ListOption onClick={() => onSelect('2nd half')}>
        Extra time
      </Styled.ListOption>
      <Styled.ListOption onClick={() => onSelect('Penalty shootout')}>
        Penalties
      </Styled.ListOption>
    </ul>
  </MotionWrapper>
);
