import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from 'shared/components/Avatar';
import { InfoBadge } from 'shared/components/InfoBadge';
import { StatsBadge } from 'shared/components/StatsBadge';
import { BorderedButton } from 'shared/components/Buttons';
import { MatchCard } from 'shared/components/match-card/MatchCard';
import { loadSquadProfile } from '../squad-profile/store/squadActions';
import { squadSelector } from '../squad-profile/store/squadSelectors';
import Styled from '../squad-invite/SquadInvite.styles';
import { profileSelector } from 'modules/profile/store/profileSelectors';
import profileActions from 'modules/profile/store/profileActions';
import { RatingValue } from 'shared/components/RatingValue';

const SquadInvite: React.FC<
  RouteComponentProps & { inviteId: string; squadId: string }
> = ({ inviteId, squadId, navigate, ...props }) => {
  console.log('SquadInvite inviteId', inviteId);
  console.log('SquadInvite squadId', squadId);
  const squadProfile = useSelector(squadSelector);
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (squadProfile != null) {
      console.log('profile effect', squadProfile.organiserId);
      dispatch(profileActions.selectProfile(squadProfile!.organiserId));
      dispatch(profileActions.loadProfile.request(squadProfile!.organiserId));
      squadId;
    } else {
      console.log('loadSquadProfile effect');
      dispatch(loadSquadProfile.request(squadId));
    }
  }, [squadId, squadProfile]);

  //   useEffect(() => {
  //       console.log("profile effect");
  //     if(squadProfile!=null){
  // debugger;
  //       dispatch(profileActions.selectProfile(squadProfile!.organiserId));
  //     }
  //   }, [inviteId, squadProfile]);

  console.log('squadProfile', squadProfile);
  console.log('profile', profile);
  return squadProfile && profile ? (
    <Styled.Page {...props}>
      <Styled.Wrapper>
        <img className="logo" src="/images/logo_footify.svg" width={177} />
        <Avatar
          size={64}
          position={profile.position}
          src={profile?.profileImagePath}
        />

        <Styled.PlayerContainer>
          <Styled.PlayerName>{profile?.fullName}</Styled.PlayerName>
          {/* <Styled.PlayerName style={{ color: Colours.secondaryText }}>
            @{profile?.playerUrl}
          </Styled.PlayerName> */}
          <Styled.PlayingStyle>
            {profile.playingStyle}. {profile.preferredFoot} footed
          </Styled.PlayingStyle>
        </Styled.PlayerContainer>

        <Styled.InviteContent style={{ fontSize: 24, marginTop: 32 }}>
          <Styled.White>{profile.fullName}</Styled.White> has invited you to
          join their squad <Styled.White>{squadProfile.name}</Styled.White>:
        </Styled.InviteContent>

        <div style={{ marginTop: 32 }}>
          <Avatar
            size={120}
            src={squadProfile.squadAvatar || '/images/squad-placeholder.svg'}
          />
        </div>
        <Styled.SquadName>{squadProfile.name}</Styled.SquadName>
        {/* <Styled.SquadLocation>@{squadProfile?.playerUrl}</Styled.SquadLocation> */}
        <Styled.SquadLocation>
          {squadProfile.aimedAt}. {squadProfile.playType}.{' '}
          {squadProfile.location}
        </Styled.SquadLocation>

        <Styled.RatingGrid style={{ marginBottom: 32 }}>
          <RatingValue
            label="GK"
            color="#065af7"
            value={squadProfile.rating.GK ?? 0}
          />
          <RatingValue
            label="DEF"
            color="#06ef83"
            value={squadProfile.rating.DEF ?? 0}
          />
          <RatingValue
            label="MID"
            color="#f2d33d"
            value={squadProfile.rating.MID ?? 0}
          />
          <RatingValue
            label="ATT"
            color="#f41043"
            value={squadProfile.rating.ATT ?? 0}
          />
        </Styled.RatingGrid>

        <Styled.InviteContent style={{ color: 'white', marginTop: 16 }}>
          To respond, please download the Footify app, create your football
          profile and click the link again.
        </Styled.InviteContent>

        <Styled.InviteContent style={{ marginTop: 16, fontSize: 12 }}>
          After installing the app and creating your profile, clicking the link
          will launch the Footify app with the Squad invite details
        </Styled.InviteContent>

        <p className="download-app" style={{ marginTop: 32 }}>
          <a
            href="https://testflight.apple.com/join/0wKIBYLQ"
            rel="noreferrer"
            target="_blank"
            style={{ marginRight: 16 }}
          >
            <img src="/images/logo_app_store.png" />
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=club.wearefootify.app"
            rel="noreferrer"
            target="_blank"
          >
            <img src="/images/logo_google_play.png" />
          </a>
        </p>

        <p
          style={{
            textAlign: 'center',
            marginTop: 32,
            fontSize: 14,
            color: 'white',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>What is Footify?</div>
          https://www.wearefootify.club
        </p>

        <img className="logo" src="/images/logo_emblem.svg" width={45} />
      </Styled.Wrapper>
    </Styled.Page>
  ) : (
    <Styled.Page {...props}>
      <Styled.Loader />
    </Styled.Page>
  );
};

export default SquadInvite;
