import axios, { AxiosResponse } from 'axios';
import { Squad } from 'shared/types/Squad';

export const fetchProfile = (
  id: string
): Promise<AxiosResponse<{ squads: Squad[] }>> =>
  axios.get<{ squads: Squad[] }>(`/api/squads/${id}`);

export const acceptInvite = (id: string): Promise<AxiosResponse> =>
  axios.post(`/api/squads/accept-invite/${id}`);

export const rejectInvite = (id: string): Promise<AxiosResponse> =>
  axios.post(`/api/squads/reject-invite/${id}`);
