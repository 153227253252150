import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  box-shadow: inset 0 -1px 0 0 ${Colours.divider};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px;
  padding: 10px 0;

  input[type='radio'] {
    all: unset;

    &:after {
      border: 2px solid ${Colours.primaryText};
      border-radius: 50%;
      content: ' ';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      font-size: 0.56rem;
      text-align: center;
    }

    &:checked:after {
      content: '\\26AA';
    }
  }
`;

export default {
  Wraper,
  RadioList,
  Item,
};
