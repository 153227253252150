import styled, { keyframes } from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const shrink = keyframes`
  0% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(0);
  }
`;

export interface Props {
  timeout: number;
}

export const TimeoutButton = styled.button<Props>`
  background-color: transparent;
  border: 2px solid ${Colours.primaryElement};
  border-radius: 20px;
  color: ${Colours.primaryText};
  cursor: pointer;
  line-height: 2rem;
  font-size: 0.9rem;
  outline: none;
  padding: 0 1rem;
  position: relative;

  &:after {
    content: ' ';
    width: 60%;
    border-bottom: 2px solid ${Colours.primaryElement};
    animation: ${(props) => props.timeout}ms ${shrink} ease-out;
    position: absolute;
    left: 21%;
    bottom: 2px;
    //transform-origin: left bottom;
  }
`;
