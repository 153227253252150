import { Colours } from '../styles/Colours';

export enum PlayingPosition {
  F = 'F',
  S = 'S',
  LW = 'LW',
  AM = 'AM',
  RW = 'RW',
  LM = 'LM',
  CM = 'CM',
  RM = 'RM',
  LWB = 'LWB',
  DM = 'DM',
  RWB = 'RWB',
  LB = 'LB',
  LCB = 'LCB',
  CB = 'CB',
  RCB = 'RCB',
  RB = 'RB',
  SW = 'SW',
  GK = 'GK',
}

export const PlayingPositionColours = [
  { name: 'F', bgColour: '#E11378', color: Colours.primaryText },
  { name: 'S', bgColour: '#F41043', color: Colours.primaryText },

  { name: 'LW', bgColour: '#E11378', color: Colours.primaryText },
  { name: 'AM', bgColour: '#E11378', color: Colours.primaryText },
  { name: 'RW', bgColour: '#E11378', color: Colours.primaryText },

  { name: 'LM', bgColour: '#F2FD3D' },
  { name: 'CM', bgColour: '#F2FD3D' },
  { name: 'RM', bgColour: '#F2FD3D' },

  { name: 'LWB', bgColour: '#A2FD3D' },
  { name: 'DM', bgColour: '#A2FD3D' },
  { name: 'RWB', bgColour: '#A2FD3D' },

  { name: 'LB', bgColour: '#06EF83' },
  { name: 'LCB', bgColour: '#06EF83' },
  { name: 'CB', bgColour: '#06EF83' },
  { name: 'RCB', bgColour: '#06EF83' },
  { name: 'RB', bgColour: '#06EF83' },

  { name: 'SW', bgColour: '#06F7CB' },

  { name: 'GK', bgColour: '#065AF7', color: Colours.primaryText },
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const propsForPosition = (
  position: PlayingPosition
): typeof PlayingPositionColours =>
  PlayingPositionColours.find((pp) => pp.name === position)!;
