import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colours.divider};
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
  }

  [type='radio'] {
    all: unset;
    border: 2px solid ${Colours.primaryText};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    position: relative;

    &:checked:after {
      content: ' ';
      background-color: ${Colours.primaryText};
      position: absolute;
      top: 25%;
      bottom: 25%;
      left: 25%;
      right: 25%;
      border-radius: 50%;
    }
  }
`;

export default {
  Wrapper,
};
