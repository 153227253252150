import React, { useState } from 'react';
import axios from 'axios';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { AnimatePresence } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateMatch } from 'shared/types/CreateMatch';
import { MatchMode } from './MatchMode';
import { Basics } from './Basics';
import { Lineup } from './Lineup';
import { MatchEvents } from './MatchEvents';
import { toast } from 'react-hot-toast';

export const validationSchema = yup.object({
  mode: yup.string().nullable().required('Please select match mode.'),
  title: yup.string().required('Please enter match title.'),
  venue: yup.string().required('Please enter match venue.'),
  kickOff: yup.string().required('Please enter kick-off time.'),
  homeTeamName: yup.string().nullable().required('Please select home team.'),
  awayTeamName: yup.string().nullable().required('Please select away team.'),
  settings: yup.object({
    date: yup.string().required('Please enter match date'),
    time: yup.string().required('Please enter match time'),
    noPlayers: yup.number().moreThan(0, 'Please enter number of players'),
  }),
});

const Steps = ['matchMode', 'basics', 'lineup', 'events'];

export const AddMatch: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<typeof Steps[number]>('matchMode');
  const [allPlayers, setAllPlayers] = useState<Record<string, any[]>>({});
  const formMethods = useForm<
    Partial<CreateMatch & { venue?: string; date?: string }>
  >({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...{
        homeTeamName: 'Home',
        awayTeamName: 'Away',
        homeTeamPlayers: [],
        awayTeamPlayers: [],
        homeTeamSubs: [],
        awayTeamSubs: [],
        events: [],
        score0: 0,
        score1: 0,
        settings: {
          haveHalfTime: false,
          noPlayers: 0,
          substitutes: 0,
          noOfSubstitutions: 0,
          length: 0,
          extraTime: 0,
          penalties: 0,
          suddenDeathPens: 0,
          date: '',
          time: '',
        },
        injuryTimeByPeriod: {
          firstHalf: 0,
          secondHalf: 0,
          extraFirstHalf: 0,
          extraSecondHalf: 0,
        },
      },
    },
  });

  const onSubmit = (values: any): void => {
    axios
      .post('/api/match/', values)
      .then(() => {
        toast.success('Match added successfully');
        navigate('/');
      })
      .catch((err) =>
        toast.error('Error saving match: ' + JSON.stringify(err))
      );
  };

  const handleTeamSelected = (id: string): void => {
    axios
      .get(`/api/squads/${id}/players`)
      .then((res) =>
        res.data.sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
      )
      .then((players) => setAllPlayers({ ...allPlayers, [id]: players }));
  };

  return (
    <AnimatePresence initial={false}>
      <FormProvider {...formMethods}>
        {
          {
            matchMode: (
              <MatchMode
                onNext={() => setStep('basics')}
                onCancel={() => navigate('/')}
              />
            ),
            basics: (
              <Basics
                onNext={() => setStep('lineup')}
                onCancel={() => navigate('/')}
              />
            ),
            lineup: (
              <Lineup
                onNext={() => setStep('events')}
                onCancel={() => navigate('/')}
                onTeamSelected={handleTeamSelected}
                allPlayers={allPlayers}
              />
            ),
            events: (
              <MatchEvents
                allPlayers={Object.values(allPlayers).flatMap((a) => a)}
                onNext={onSubmit}
              />
            ),
          }[step]
        }
      </FormProvider>
    </AnimatePresence>
  );
};
