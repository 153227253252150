import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Colours } from 'shared/styles/Colours';
import { CreateMatch } from 'shared/types/CreateMatch';
import { MotionWrapper } from './AddEvent';
import { Header } from '../Header';
import { useFormContext } from 'react-hook-form';
import { ValidationError } from '../../../shared/components/validation-error/ValidationError';

const Time = styled.input`
  all: unset;
  font-size: 6rem;
  color: ${Colours.subheader};
  border-bottom: 1px solid ${Colours.divider};
  text-align: center;
  width: 100%;
`;

const Hint = styled.div`
  text-align: center;
  margin-top: 1rem;

  span {
    color: ${Colours.subheader};
  }
`;

export const EventTime: React.FC<{
  title: string;
  onBack: () => void;
  onNext: (time: string) => void;
}> = ({ title, onBack, onNext }) => {
  const { getValues } = useFormContext<CreateMatch>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [time, setTime] = useState('');
  const mask = /\d{1,3}(?:\+\d?)?/;
  const [error, setError] = useState('');

  const handleInput = (value: string): void => {
    const result = value.match(mask);
    if (value.length < time.length) {
      setTime(value);
    } else if (result && result[0] === value) {
      setTime(value);
    }
  };

  const handleNext = (): void => {
    const [eventTime, eventExtraTime] = time.split('+');
    const {
      settings: { haveHalfTime, length, extraTime },
    } = getValues();
    const halfLength = haveHalfTime ? length / 2 : length;
    const halfExtraLength = haveHalfTime ? extraTime / 2 : extraTime;
    if (
      eventExtraTime &&
      ![
        halfLength,
        length,
        length + halfExtraLength,
        length + extraTime,
      ].includes(Number(eventTime))
    ) {
      setError('Injury time can only be added after half time is over.');
    } else {
      time.length > 0 ? onNext(time) : null;
    }
  };

  useEffect(() => inputRef.current?.focus(), []);

  return (
    <MotionWrapper>
      <Header
        rightButtonColour="white"
        onLeftButtonClick={onBack}
        onRightButtonClick={handleNext}
        title={title}
      />
      <Time
        ref={inputRef}
        onChange={(e) => handleInput(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleNext()}
        value={time}
        placeholder="0"
      />
      <ValidationError message={error} />
      <Hint>
        Time of the incident.
        <br />
        For injury time type ‘+’ example ‘45+3’
        <br />
        <span>(mins)</span>
      </Hint>
    </MotionWrapper>
  );
};
