import React, { useEffect } from 'react';
import { useNotifications } from '../shared/hooks/useNotifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  authdUserProfileSelector,
  userNotifications,
} from '../modules/profile/store/profileSelectors';
import uiActions from '../store/uiActions';
import { NotificationItem } from '../shared/components/notification-item/NotificationItem';
import { Notification } from '../shared/types/Notification';
import { toast } from 'react-hot-toast';

export const AppNotification: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(userNotifications);
  const userProfile = useSelector(authdUserProfileSelector);
  const newNotifications = useNotifications(userProfile?.userId);

  useEffect(() => {
    if (!newNotifications?.length) {
      return;
    }

    // dispatch all updates
    dispatch(uiActions.loadNotifications.success(newNotifications));

    // but render toast only for new incoming notifications
    const existingIds = notifications.map((n) => n.id);
    const filtered = newNotifications.filter(
      (n) => existingIds.indexOf(n.id) === -1
    );

    filtered?.map((item) =>
      toast(<NotificationItem notification={Notification.fromJson(item)} />)
    );
  }, [newNotifications]);

  return null;
};
