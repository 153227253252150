import styled from 'styled-components/macro';
import { Colours } from '../styles/Colours';
import React from 'react';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${Colours.box};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: 72px;
  justify-content: center;
  margin: 5px;
  width: 108px;
`;

const Title = styled.div`
  color: ${Colours.secondaryText};
`;
export interface Props {
  counter?: number;
  title: string;
}

export const InfoBadge: React.FC<Props> = ({ counter, title, ...props }) => (
  <Wrapper {...props}>
    {counter || '0'}
    <Title>{title}</Title>
  </Wrapper>
);
