import styled from 'styled-components/macro';
import { Colours } from '../../styles/Colours';
import { Avatar as _Avatar } from '../Avatar';

const Wrapper = styled.div`
  background-color: ${Colours.box};
  border-radius: 4px;
  flex: 1;
  padding: 1.25rem 1rem;
  font-size: 1rem;
`;

const MatchTime = styled.div`
  color: ${Colours.secondaryText};
  font-size: 0.75rem;
  line-height: 1.65em;
`;

const MatchLength = styled.div`
  color: ${Colours.primaryText};
  font-size: 0.9rem;
  height: 1.3rem;
  margin-bottom: 1rem;
`;

const Score = styled.div<{ score: number }>`
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding-left: 60px;
  position: relative;
  height: 40px;

  &:before {
    align-self: flex-start;
    align-items: center;
    background-color: #282c2e;
    border-radius: 4px;
    content: '${(props) => props.score}';
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    top: calc(50% - 20px);
    position: absolute;
    width: 48px;
  }

  h5 {
    margin: 0;
    line-height: 1.5rem;
  }

  small {
    font-size: 12px;
    color: ${Colours.secondaryText};
  }
`;

const Avatar = styled(_Avatar)`
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

const Players = styled.div`
  margin-top: 1.5rem;
  display: flex;
  font-size: 0.75rem;
  justify-content: flex-start;
  align-items: center;

  ${Avatar} {
    &:nth-child(2) {
      position: relative;
      left: -20px;
    }
  }

  svg {
    width: 1.8rem;
    height: 0.8rem;
  }

  .name {
    font-weight: bold;
    margin-left: 1rem;
  }

  .hint {
    color: ${Colours.secondaryText};
  }
`;

export default {
  Wrapper,
  MatchTime,
  MatchLength,
  Score,
  Players,
  Avatar,
};
