import React, { ReactNode } from 'react';

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component {
  state: State = {
    hasError: false,
  };

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): void {
    // Update state so the next render will show the fallback UI.
    //  return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // You can also log the error to an error reporting service
    // TODO: papertrail or sentry?
    // logErrorToMyService(error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
