import React from 'react';

export const IcoCircleEmpty: React.FC<{ value: number }> = (props) => (
  <svg
    shapeRendering="geometricPrecision"
    textRendering="optimizeLegibility"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <g>
      <circle strokeWidth="1.5" cx="15" cy="15" r="13" stroke="white" />
      <text
        x="50%"
        y="65%"
        textAnchor="middle"
        stroke="#ffffff"
        fontSize={props.value < 10 ? 13 : 11.5}
      >
        {props.value}
      </text>
    </g>
  </svg>
);

export const IcoCircleFull: React.FC<{ value: number }> = (props) => (
  <svg
    shapeRendering="geometricPrecision"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 30 30"
    width="30"
    height="30"
  >
    <g>
      <circle
        strokeWidth="1.4"
        cx="15"
        cy="15"
        r="13"
        stroke="white"
        fill="white"
      />
      <text
        x="50%"
        y="65%"
        textAnchor="middle"
        stroke="#000000"
        fontSize={props.value < 10 ? 13 : 11.5}
      >
        {props.value}
      </text>
    </g>
  </svg>
);
