import React from 'react';

export const IcoCrossCircle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <rect width="32" height="32" rx="16" fill="#282C2E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5595 19.0207L17.5365 15.9993L20.5572 12.9763C20.9059 12.6291 20.9059 12.0706 20.5587 11.722L20.278 11.4406C20.1131 11.2713 19.8874 11.1794 19.6501 11.1794H19.6487C19.4114 11.1794 19.1886 11.2727 19.0222 11.4398L16 14.4643L12.9771 11.4435C12.6306 11.0963 12.0728 11.0949 11.7242 11.4421L11.4428 11.7206C11.2735 11.887 11.1809 12.1127 11.1809 12.3492C11.1809 12.5872 11.2742 12.8107 11.4413 12.9778L14.4658 16L11.4443 19.023C11.0956 19.3702 11.0956 19.9287 11.4428 20.2781L11.7235 20.5587C11.8899 20.728 12.1149 20.8199 12.3521 20.8199C12.5879 20.8199 12.8136 20.7258 12.98 20.5595L16.0015 17.5358L19.0237 20.5559C19.1951 20.7288 19.423 20.8164 19.6516 20.8164C19.878 20.8164 20.1044 20.7303 20.278 20.5574L20.5587 20.2774C20.7287 20.1125 20.8206 19.8868 20.8206 19.6496C20.822 19.4123 20.7287 19.188 20.5616 19.0209L20.5595 19.0207Z"
      fill="white"
    />
  </svg>
);
