import React from 'react';
import { Link } from '@reach/router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import uiActions from '../../../store/uiActions';
import { Colours } from '../../styles/Colours';
import { Button } from '../buttons/Button';

const MenuWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  li {
    box-shadow: inset 0 -1px 0 0 ${Colours.divider};
    padding: 1rem 0;
  }

  span {
    cursor: pointer;
    line-height: 3rem;
  }
`;

const Menu: React.FC<{ onLogout: () => void }> = ({ onLogout, ...props }) => {
  const dispatch = useDispatch();

  const closeDrawer = (): void => {
    dispatch(uiActions.sidebarChange({ component: null }));
  };

  return (
    <MenuWrapper>
      <ul onClick={closeDrawer} {...props}>
        <li>
          <Link to="/edit-profile">Edit profile</Link>
        </li>
        <li>
          <Link to="/add-match">Add a match</Link>
        </li>
      </ul>
      <Button onClick={onLogout}>Log out</Button>
    </MenuWrapper>
  );
};

export default Menu;
