import React from 'react';
import Rating from 'react-rating';
import { IcoCircleEmpty, IcoCircleFull } from '../icons/circle';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  min-width: 275px;
`;

export const RatingInput: React.FC<{
  readonly?: boolean;
  initialRating?: number;
  onChange: (value: number) => void;
}> = ({ readonly = false, initialRating = 0, onChange, ...props }) => (
  <Wrapper>
    <Rating
      {...props}
      readonly={readonly}
      start={2}
      stop={10}
      emptySymbol={[
        <IcoCircleEmpty key={3} value={3} />,
        <IcoCircleEmpty key={4} value={4} />,
        <IcoCircleEmpty key={5} value={5} />,
        <IcoCircleEmpty key={6} value={6} />,
        <IcoCircleEmpty key={7} value={7} />,
        <IcoCircleEmpty key={8} value={8} />,
        <IcoCircleEmpty key={9} value={9} />,
        <IcoCircleEmpty key={10} value={10} />,
      ]}
      fullSymbol={[
        <IcoCircleFull key={3} value={3} />,
        <IcoCircleFull key={4} value={4} />,
        <IcoCircleFull key={5} value={5} />,
        <IcoCircleFull key={6} value={6} />,
        <IcoCircleFull key={7} value={7} />,
        <IcoCircleFull key={8} value={8} />,
        <IcoCircleFull key={9} value={9} />,
        <IcoCircleFull key={10} value={10} />,
      ]}
      initialRating={initialRating}
      onChange={onChange}
    />
  </Wrapper>
);
