import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CreateMatch } from 'shared/types/CreateMatch';
import { Avatar } from 'shared/components/Avatar';
import { Sidebar } from 'shared/components/sidebar/sidebar';
import { ValidationError } from 'shared/components/validation-error/ValidationError';
import { Header } from './Header';
import { SelectSquad } from './SelectSquad';
import Styled from './AddMatch.styles';
import { SelectPlayers } from './SelectPlayers';

interface Props {
  onNext: () => void;
  onCancel: () => void;
  onTeamSelected: (id: string) => void;
  allPlayers: Record<string, any[]>;
}

const Player: React.FC<{ player: any }> = ({ player }) => (
  <Styled.Player>
    <Avatar
      size={56}
      position={player.position}
      src={player.profileImagePathThumbnail || player.profileImagePath}
    />
    <div className="full-name">{player.fullName}</div>
    <div className="playing-style">{player.playingStyle}</div>
  </Styled.Player>
);

export const Lineup: React.FC<Props> = ({
  onNext,
  onCancel,
  onTeamSelected,
  allPlayers,
}) => {
  const {
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CreateMatch>();

  const [activeSide, setActiveSide] = useState<'home' | 'away' | null>(null);
  const values = getValues();

  const handleSquadSelect = ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }): void => {
    if (values.matchMode === 'squadMode') {
      setValue('homeTeamName', name);
      setValue('homeSquadId', id);
      setValue('awayTeamName', name);
      setValue('awaySquadId', id, { shouldValidate: true });
    } else {
      setValue(`${activeSide}TeamName`, name);
      setValue(`${activeSide}SquadId`, id, { shouldValidate: true });
    }

    if (id === '0') {
      setActiveSide(null);
    } else {
      onTeamSelected(id);
    }
  };

  const activeSideSquadId =
    activeSide === 'home' ? values.homeSquadId : values.awaySquadId;

  const teamMembers = allPlayers[activeSideSquadId];
  const players: string[] = values[`${activeSide}TeamPlayers`];
  const subs: string[] = values[`${activeSide}TeamSubs`];

  const handleAddPlayer = (id: string, type: 'Sub' | 'Start'): void => {
    if (type === 'Sub') {
      if (players.includes(id)) {
        setValue(
          `${activeSide}TeamPlayers`,
          players.filter((pid) => pid !== id)
        );
        setValue(`${activeSide}TeamSubs`, [...subs, id], {
          shouldValidate: true,
        });
      } else if (subs.includes(id)) {
        setValue(
          `${activeSide}TeamSubs`,
          subs.filter((pid) => pid !== id),
          { shouldValidate: true }
        );
      } else {
        setValue(`${activeSide}TeamSubs`, [...subs, id], {
          shouldValidate: true,
        });
      }
    } else {
      if (subs.includes(id)) {
        setValue(
          `${activeSide}TeamSubs`,
          subs.filter((pid) => pid !== id)
        );
        setValue(`${activeSide}TeamPlayers`, [...players, id], {
          shouldValidate: true,
        });
      } else if (players.includes(id)) {
        setValue(
          `${activeSide}TeamPlayers`,
          players.filter((pid) => pid !== id),
          { shouldValidate: true }
        );
      } else {
        setValue(`${activeSide}TeamPlayers`, [...players, id], {
          shouldValidate: true,
        });
      }
    }
  };

  return (
    <Styled.Wrapper
      initial={{ opacity: 0, transform: 'translateX(100%)' }}
      animate={{ opacity: 1, transform: 'translateX(0px)' }}
      exit={{ opacity: 0, transform: 'translateX(-100%)' }}
    >
      {activeSide ? (
        <Sidebar width="400px" showsClose={false}>
          {activeSideSquadId && activeSideSquadId !== '0' ? (
            <SelectPlayers
              title={`${activeSide} players`}
              noPlayers={values.settings.noPlayers}
              noSubstitutes={values.settings.substitutes}
              players={teamMembers}
              selectedPlayers={players}
              selectedSubs={subs}
              onPlayerSelect={handleAddPlayer}
              onDone={() => setActiveSide(null)}
            />
          ) : (
            <SelectSquad
              title={`${activeSide} squad`}
              onSquadSelect={handleSquadSelect}
              onCancel={() => setActiveSide(null)}
            />
          )}
        </Sidebar>
      ) : null}

      <Header
        leftButtonTitle="Cancel"
        onLeftButtonClick={onCancel}
        onRightButtonClick={() => {
          trigger(['homeTeamName', 'awayTeamName']).then((isValid) => {
            isValid && onNext();
          });
        }}
      />

      <Styled.Lineup>
        <div className="squad-header">
          <ValidationError message={errors.homeTeamName?.message} />
          <h5>
            Home squad <div>{values.homeTeamName ?? '-'}</div>
          </h5>
          <button className="add" onClick={() => setActiveSide('home')}>
            +
          </button>
        </div>
        <div className="vs">VS</div>
        <div className="squad-header">
          <ValidationError message={errors.awayTeamName?.message} />
          <h5>
            Away squad <div>{values.awayTeamName ?? '-'}</div>
          </h5>
          <button className="add" onClick={() => setActiveSide('away')}>
            +
          </button>
        </div>

        <div>
          {values?.homeTeamPlayers.map((id: string) => {
            const player = allPlayers[values.homeSquadId!]?.find(
              (p) => p.userId === id
            );
            return player && <Player key={id} player={player} />;
          })}
          {values?.homeTeamSubs.map((id: string) => {
            const player = allPlayers[values.homeSquadId!]?.find(
              (p) => p.userId === id
            );
            return player && <Player key={id} player={player} />;
          })}
        </div>
        <div></div>
        <div>
          {values?.awayTeamPlayers.map((id) => {
            const player = allPlayers[values.awaySquadId!]?.find(
              (p) => p.userId === id
            );
            return player && <Player key={id} player={player} />;
          })}
          {values?.awayTeamSubs.map((id) => {
            const player = allPlayers[values.awaySquadId!]?.find(
              (p) => p.userId === id
            );
            return player && <Player key={id} player={player} />;
          })}
        </div>
      </Styled.Lineup>
    </Styled.Wrapper>
  );
};
