import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase';
import styled from 'styled-components/macro';
import { PlayingPosition } from '../types/PlayingPosition';
import { PositionBadge as _PositionBadge } from './PositionBadge';

const Wrapper = styled.div`
  position: relative;
`;

const ProfilePic = styled.img<{ src?: string; size?: number }>`
  width: ${(props) => props.size || 56}px;
  height: ${(props) => props.size || 56}px;
  border-radius: 50%;
  background-color: transparent;
  object-position: top center;
  object-fit: cover;
`;

const PositionBadge = styled(_PositionBadge)<{ scale?: number }>`
  position: absolute;
  bottom: 0px;
  left: 0px;
  // width:40px;
  // height:40px;
  transform: scale(${(props) => props.scale ?? 1});
  transform-origin: 0% 90%;
`;

export interface Props {
  src?: string;
  size?: number;
  position?: PlayingPosition;
}

export const Avatar: React.FC<Props> = ({
  src,
  position,
  size = 56,
  ...props
}) => {
  const scale = size / 140;
  const [imgSrc, setImgSrc] = useState<string>('/images/avatar.svg');
  const imgRef = useRef(null);

  useEffect(() => {
    if (src?.startsWith('http')) {
      firebase.storage().refFromURL(src).getDownloadURL().then(setImgSrc);
    } else {
      src && setImgSrc(src);
    }
  }, [src]);

  return (
    <Wrapper {...props}>
      <ProfilePic src={imgSrc} size={size} ref={imgRef} />
      {position && <PositionBadge position={position} scale={scale} />}
    </Wrapper>
  );
};
