import React from 'react';
import Styled from './RadioList.styles';

export interface Props {
  title: string;
  name: string;
  options: (string | { value: string; label: string })[];
  value: string | number;
}

const _RadioList: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { title, options, name, value, ...props },
  ref
) => (
  <Styled.Wraper>
    <h6>{title}</h6>
    <Styled.RadioList>
      {options.map((option) => {
        let itemValue, itemLabel;
        if (typeof option === 'string') {
          itemValue = itemLabel = option;
        } else {
          itemValue = option.value;
          itemLabel = option.label;
        }

        const id = `${name}_${itemValue.toLowerCase().replace(/[^a-z]/g, '')}`;

        return (
          <Styled.Item key={id}>
            <label htmlFor={id}>{itemLabel}</label>
            <input
              id={id}
              ref={ref}
              name={name}
              type="radio"
              value={itemValue}
              defaultChecked={value === itemValue}
              {...props}
            />
          </Styled.Item>
        );
      })}
    </Styled.RadioList>
  </Styled.Wraper>
);

export const RadioList = React.forwardRef(_RadioList);
