import { useContext, useEffect, useRef, useState } from 'react';
import firebase from 'firebase';
import { Notification } from 'shared/types/Notification';
import { AuthContext } from '../services/auth/AuthContext';

export function useNotifications(userId?: string): Notification[] | null {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { firebase } = useContext(AuthContext);
  const isInitialLoad = useRef(true);

  const handleNotifications = (data: any[]): void => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    setNotifications(
      data.map(
        ({
          is_readNotification: isRead,
          notification_id: id,
          acceptRequest,
          type,
          ...data
        }) => {
          const category =
            [
              'receive squad request',
              'receiveMatchInvitation',
              'receiveFixtureInvition',
            ].indexOf(data.notificationType) !== -1
              ? 'received'
              : 'general';

          return {
            id,
            isRead,
            category,
            acceptRequest,
            ...data,
          };
        }
      )
    );
  };

  useEffect(() => {
    if (!userId || !firebase) {
      return undefined;
    }

    const unsubscribeRI = firebase
      .firestore()
      .collection('ReceiveInvites')
      .where('playerId', '==', userId)
      .onSnapshot((result: firebase.firestore.QuerySnapshot) => {
        handleNotifications(
          result
            .docChanges()
            .map((changes) => changes.doc.data())
            .filter((data) => {
              return (
                data.notification_id &&
                (data.notificationType !== 'receiveFixtureInvition' ||
                  data.acceptRequest === 1)
              );
            })
        );
      });

    return () => {
      unsubscribeRI();
    };
  }, [userId]);

  return notifications;
}
