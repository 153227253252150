import { createAsyncAction } from 'typesafe-actions';
import { Match } from 'shared/types/Match';

export const loadMatch = createAsyncAction(
  'match/LOAD',
  'match/LOAD_SUCCESS',
  'match/LOAD_FAILURE'
)<string, Match, Error>();

export default {
  loadMatch,
};
