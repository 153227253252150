import React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import { MatchEvent } from 'shared/types/MatchEvent';
import { IcoGoal } from 'shared/icons/goal';
import { IcoPenMissed } from 'shared/icons/pen-missed';
import { IcoPenSaved } from 'shared/icons/pen-saved';
import { Colours } from '../../../shared/styles/Colours';
import { ResultIcon } from '../utils';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(30%, 200px) 60px 40px 60px minmax(30%, 200px);
  grid-row-gap: 20px;
  margin-bottom: 40px;
`;

const EventPlayers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;

  .assist {
    font-size: 0.8rem;
  }
`;

const EventType = styled.div`
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.2rem;
  }
`;

const EventTime = styled.div`
  background-color: white;
  border-radius: 50%;
  background-color: ${Colours.primaryDark};
  flex: 0 0 40px;
  height: 40px;
`;

export const PenShootoutEvent: React.FC<{
  eventHome: MatchEvent;
  eventAway: MatchEvent;
}> = ({ eventHome, eventAway, ...props }) => (
  <Wrapper {...props}>
    <EventPlayers>
      {eventHome.player1?.fullName ?? 'Mystery PLayer'}
    </EventPlayers>
    <EventType>{ResultIcon[eventHome.penaltyResult]}</EventType>
    <EventTime />
    <EventType>{ResultIcon[eventAway.penaltyResult]}</EventType>
    <EventPlayers>
      {eventAway.player1?.fullName ?? 'Mystery Player'}
    </EventPlayers>
  </Wrapper>
);
